import {authHeader, ApiConfigs} from "../_helpers";

export const productService = {
    getProducts,
    getProductsByCategory,
    getProductsFilter,
    getById,
    getVariantByAttribute
};

function getProducts(currentPage: string, perPage: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?per_page=${perPage}&current_page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function getProductsFilter(key: string, filter: string, param: any = "") {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?pagination=off&key=${key}&value=${filter}${param}`, requestOptions)
        .then(
            handleResponse
        );
}

function getProductsByCategory(id: string, param: any = "") {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?pagination=off&key=category_id&value=${id}${param}`, requestOptions)
        .then(
            handleResponse
        );
}

function getById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getVariantByAttribute(id_product: string, params: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.getVariantByAttribute.replace(':id', id_product)+params}`, requestOptions)
        .then(
            handleResponse
        );
}

function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}