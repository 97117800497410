<template>
    <div class="bg-[#202020] text-white flex flex-col w-screen h-screen">
        <div class="flex items-center justify-center px-4 py-12">
            <img src="/posys-logo-white2.png" width="280" />
        </div>
        <div class="flex justify-center items-center flex-grow">
            <div v-if="suidSelected">
                <div v-if="!loginVerificationShow" class="flex flex-col">
                    <span class="text-9xl">{{ authCode?.replace(/(\d{3})(\d{3})/, "$1-$2") }}</span>
                    <span v-if="!refreshButtonStatus" class="mt-4 relative py-2">{{ $t('Pos.CodeResets') }} <span
                            class="underline">{{ countdown + " " + $t('Pos.Seconds') }}</span></span>
                    <span v-else @click="forceRerender" class="mt-4 underline">{{ $t('Pos.RefreshCode') }}</span>
                </div>
                <div v-else class=" flex flex-col text-left text-3xl gap-4">
                    <div>
                        <span>{{ $t('Pos.ConnectFrom') }}</span>
                        <span>{{ storeName }}</span>
                    </div>
                    <div>
                        <span>{{ $t('Pos.DeviceName') + " : " }}</span>
                        <span>{{ deviceName }}</span>
                    </div>
                    <div class="flex flex-col gap-6">
                        <button @click="authCodeConfirm"
                            class="border-2 border-white p-4 text-white focus:bg-white focus:text-black">{{
                                $t('Pos.Confirm') }}</button>
                        <button @click="cancelLoginProcess"
                            class="border-2 border-white p-4 text-white focus:bg-white focus:text-black">{{
                                $t('Pos.Cancel') }}</button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-8" v-else>
                <span class="text-2xl">{{ $t('Pos.EnterStoreName') }}</span>
                <div class="flex flex-row">
                    <input id="suidInput" @click="selectInput" @input="getSuid" type="text"
                        class="text-[#353535] py-2 px-3 text-3xl" />
                    <div class="bg-black relative" @click="openKeyboard">
                        <iconKeyboard :size="10" :color="'#fff'"
                            style="width: 40px; height: 100%; transform: translate(0, 0); margin: 0 10px;" />
                    </div>
                </div>
                <button @click="confirmSiud"
                    class="border-2 border-white p-4 text-white focus:bg-white focus:text-black">{{ $t('Pos.Confirm')
                    }}</button>
                <span v-if="showErrorCode" class="mt-4 relative py-2">{{ $t('Pos.ErrorAuthCode') }}</span>
            </div>
        </div>
        <div class="flex flex-col justify-center items-center gap-x-5 px-10 h-36 relative">
            <!-- <div v-show="suidSelected" id="countdown">
                <div id="countdown-number"></div>
                <svg>
                    <circle r="36" cx="40" cy="40"></circle>
                </svg>
            </div> -->
            <button v-if="suidSelected" @click="returnSuid"
                class="border-2 border-white w-fit px-8 py-3 mt-2 text-white focus:bg-white focus:text-black">{{
                    $t('Pos.Return') }}</button>
        </div>
        <LbrxKeyboardPopup :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
    </div>
</template>

<script>
import { authService } from "@/_services"
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import iconKeyboard from "@/components/icons/iconKeyboard.vue"
import webSocketService from '@/_services/webSocketService';
import router from "@/router";
import { nextTick } from "vue";
export default {
    name: "authCode",
    inject: ['echo'],
    components: {
        iconKeyboard,
        LbrxKeyboardPopup
    },
    data() {
        return {
            authCode: "",
            loginVerificationShow: false,
            dataPusher: {},
            requestLimit: 0,
            refreshButtonStatus: false,
            countdownInerval: null,
            suidSelected: false,
            suid: "",
            socketDisplay: null,
            itemDisplayLcd: "",
            modalKeyboard: {
                active: false
            },
            countdown: null,
            deviceName: "",
            storeName: "",
            showErrorCode: false
        };
    },
    created() {
        if (router.currentRoute.value.query.suid) {
            this.suid = router.currentRoute.value.query.suid;
            this.generateAuthCode();
            // beginning the countdown timer
            this.executeTimer();
        }
    },
    watch: {
        authCode: function () {
            // watch it
            this.echo.channel("device-auth-" + this.authCode).listen(
                ".device-auth",
                function (res) {
                    console.log("listening ....");
                    this.loginVerificationShow = true;
                    this.dataPusher = res;
                    this.deviceName = res.device.name;
                    this.storeName = JSON.parse(res.device.config).store.name;
                }.bind(this)
            );
        },
    },
    mounted() {
        this.socketDisplay = webSocketService.getSocket();
    },
    beforeUnmount() {
        clearInterval(this.countdownInerval);
    },
    methods: {
        generateAuthCode() {
            const inputValue = document.getElementById("suidInput");
            if (inputValue) {
                this.suid = inputValue.value;
            }

            let payload = {
                suid: this.suid
            }
            authService.generateCode(payload).then((res) => {
                this.authCode = res.code;
                this.requestLimit += 1;
                this.suidSelected = true;
                this.showErrorCode = false;
            }).catch((error) => {
                this.showErrorCode = true;
                this.requestLimit = 0;
                clearInterval(this.countdownInerval);
                console.log("error api", error);
            });
        },
        authCodeConfirm() {
            let payload = {
                suid: this.suid.toLowerCase(),
                device_id: this.dataPusher.device.id,
                auth_token: this.dataPusher.token
            }
            authService.authCodeConfirm(payload).then((res) => {
                if (res.device.config) {
                    this.itemDisplayLcd = JSON.parse(res.device.config).posConfig.showTextLcd;
                    this.setThemeTemplate(JSON.parse(res.device.config).store);
                } else {
                    this.setThemeTemplateDefault();
                }
                this.sendMessage();
                localStorage.setItem("device", JSON.stringify(res.device));
                router.push("/login");
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        cancelLoginProcess() {
            this.loginVerificationShow = false;
            this.refreshButtonStatus = false;
            this.requestLimit = 0;
            this.dataPusher = {};
            this.forceRerender();
            this.generateAuthCode();
        },
        setThemeTemplate(theme) {
            const themeTemplate = {
                primaryColor: theme.primaryColor,
                primaryLight: theme.primaryLight,
                secondaryColor: theme.secondaryColor,
                confirmationColor: "#09aa29",
                cart: {
                    selectCartItemColor: "#09aa29"
                }
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
        setThemeTemplateDefault() {
            const themeTemplate = {
                primaryColor: "#fc8019",
                primaryLight: "#fff2e8",
                secondaryColor: "#9ca3af",
                confirmationColor: "#09aa29",
                cart: {
                    selectCartItemColor: "#09aa29"
                }
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
        executeTimer() {
            // let countdownNumberEl = document.getElementById("countdown-number");
            this.countdown = 60;

            // countdownNumberEl.textContent = countdown;

            this.countdownInerval = setInterval(
                function () {
                    if (this.countdown == 1) {
                        if (this.requestLimit >= 2) {
                            clearInterval(this.countdownInerval);
                            this.refreshButtonStatus = true;
                        } else {
                            this.generateAuthCode();
                        }
                    }
                    this.$nextTick(() => {
                        this.countdown = --this.countdown <= 0 ? 60 : this.countdown;
                    })

                    // countdownNumberEl.textContent = this.countdown;
                }.bind(this),
                1000
            );
        },
        forceRerender() {
            this.requestLimit = 0;
            this.refreshButtonStatus = false;
            setTimeout(() => {
                clearInterval(this.countdownInerval);
                this.generateAuthCode();
                this.executeTimer();
            }, 500);
        },
        confirmSiud() {
            this.generateAuthCode();
            // beginning the countdown timer
            this.executeTimer();
        },
        returnSuid() {
            this.suidSelected = false;
            this.refreshButtonStatus = false;
            clearInterval(this.countdownInerval);
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socketDisplay = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socketDisplay.onopen = (event) => {
                this.socketDisplay.send(token); // send the token as soon as the connection is established
            };

            this.socketDisplay.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socketDisplay.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socketDisplay.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socketDisplay.readyState === WebSocket.OPEN) {
                const message = {
                    action: "display_item",
                    data: this.itemDisplayLcd
                };
                this.socketDisplay.send(JSON.stringify(message));
                this.loadingPrinting = false;
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        },
        openKeyboard() {
            this.modalKeyboard.active = true;
        },
        getSuid(event) {
            const inputValue = event.target.value;
            this.suid = inputValue.value;
        },
        closePopupKeyboard(text) {
            const inputValue = document.getElementById("suidInput");
            inputValue.value = text;
            this.suid = inputValue.value;
            this.modalKeyboard.active = false;
        }
    }
};
</script>

<style scoped>
/* timer part */


.button-refresh {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 60px;
    height: 100px;
    text-align: center;
}
</style>