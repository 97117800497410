<template>
    <div class="bg-white h-auto flex flex-col rounded-md px-4 py-3">
        <div>
            <div class="flex px-2 py-3 border-b-2 border-b-gray-200">
                <div class="max-w-fit my-auto">
                    <iconSearchV2 :size="20" :color="'#000'" />
                </div>
                <input id="searchBarCustomer" @click="selectInput" @input="searchCustomers" type="text"
                    class="w-[25rem] mx-2 focus:outline-none text-md" :placeholder="$t('Pos.SearchCustomers')">
            </div>
        </div>
        <div v-if="selectedCustomer.id" class="flex justify-between my-4">
            <div class="flex">
                <div>
                    <img :src="selectedCustomer.photo ? selectedCustomer.photo : '/default_img.jpg'"
                        class="rounded-xl w-28" alt="">
                </div>
                <div class="flex flex-col font-semibold justify-between mx-4">
                    <div>
                        <div class="flex gap-4 items-baseline">
                            <span class="text-3xl">{{ selectedCustomer.first_name + " " + selectedCustomer.last_name
                                }}</span>
                            <span></span>
                        </div>
                        <div class="flex flex-col items-start opacity-40">
                            <span>{{ selectedCustomer.email }}</span>
                            <span>{{ selectedCustomer.phone }}</span>
                        </div>
                    </div>
                    <div class="flex">
                        <a-button @click="navigateUpdateCustomer" type="text"
                            style="display: flex; align-items: start; font-size: 16px;">
                            <template #icon>
                                <EditOutlined :style="{ fontSize: '20px' }" />
                            </template>
                            {{ $t("Pos.Edit") }}
                        </a-button>
                        <a-button @click="showSales" type="text"
                            style="display: flex; align-items: start; font-size: 16px;">

                            <template #icon>
                                <DollarCircleOutlined v-if="!showSalesCustomer" :style="{ fontSize: '20px' }" />
                            </template>
                            {{ showSalesCustomer ? $t("Pos.Return") : $t("Pos.ShowSales") }}
                        </a-button>
                    </div>
                </div>
            </div>
            <div class="flex items-end">
                <a-button @click="chooseCustomer" class="text-primary border-primary font-semibold border-2">{{
                        $t("Pos.Select")
                    }}</a-button>
            </div>
        </div>
        <span class="flex text-2xl font-semibold my-6">{{ $t('Pos.RecentCustomers') }}</span>
        <div v-if="!showSalesCustomer" class="overflow-auto flex-grow">
            <a-list :loading="initLoading" item-layout="horizontal" :data-source="customers" :split="false">

                <template #renderItem="{ item, index }">
                    <a-list-item class="text-left rounded-md text-white" :style="getListStyle(index, item)"
                        @click="selectCustomer(item)">
                        <a-skeleton avatar :title="false" :loading="!!item.loading" active>
                            <a-list-item-meta :description="item.phone">
                                <template #title>
                                    <span>{{ item.first_name+' '+item.last_name }}</span>
                                </template>

                                <template #avatar>
                                    <img :src="item.img ? item.img : '/default_img.jpg'" style="width: 50px;"
                                        class="rounded-md" />
                                </template>
                            </a-list-item-meta>
                            <div class="font-semibold">{{ formatDate(item.created_at) }}</div>
                        </a-skeleton>
                    </a-list-item>
                </template>
            </a-list>
        </div>
        <div v-else>
            <a-table class="ant-table-striped" size="middle" :columns="columns" :data-source="selectedCustomer.sales"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)" :pagination="false">
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'is_paid'">
                        <a>{{ text == 1 ? $t('Pos.Paid') : $t('Pos.NotPaid') }}</a>
                    </template>
                    <template v-if="column.dataIndex === 'total'">
                        <a>{{ formatAmount(record.total, currencyCode, decimalPrecision) }}</a>
                    </template>
                </template>
            </a-table>
        </div>
        <LbrxKeyboardPopup :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
    </div>
</template>


<script>
import iconSearchV2 from "./icons/iconSearchV2.vue"
import { EditOutlined, DollarCircleOutlined } from '@ant-design/icons-vue';
import moment from "moment/moment";
import router from "@/router";
import LbrxKeyboardPopup from "./LbrxKeyboardPopup.vue";
import { currency } from "@/_helpers";

export default {
    name: "LbrxListCustomers",
    components: {
        EditOutlined,
        DollarCircleOutlined,
        iconSearchV2,
        LbrxKeyboardPopup
    },
    props: {
        customers: {
            required: true,
            value: Array
        },
        selectedCustomer: {
            required: true,
            value: Object
        },
        searchInputModel: {
            required: true,
            value: String
        },
        showSalesCustomer: {
            required: true,
            value: Boolean
        },
        posConfig: {
            required: true,
            value: Object
        },
        currencyCode: {
            required: false,
            value: String
        },
        decimalPrecision: {
            required: false,
            value: Number,
            default: 3
        }
    },
    data() {
        return {
            data: [
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
                {
                    img: '/profile_img.jpg',
                    name: 'oussema oussema',
                    email: 'oussema@liberrex.com',
                    created_at: '16/02/2020',
                },
            ],
            columns: [
                {
                    title: this.$t('Pos.Status'),
                    dataIndex: 'is_paid'
                },
                {
                    title: this.$t('Pos.DiscountType'),
                    dataIndex: 'discount_type',
                },
                {
                    title: this.$t('Pos.TotalPrice'),
                    dataIndex: 'total',
                },
            ],
            timer: null,
            modalKeyboard: {
                active: false
            },
        };
    },
    methods: {
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        getListStyle(index, item) {
            return {
                backgroundColor: this.selectedCustomer.id == item.id ? JSON.parse(localStorage.getItem('themeTemplate')).primaryLight : index % 2 == 0 ? '#f5f5f5' : '',
            }
        },
        formatDate(date) {
            return moment(date).format('L')
        },
        showSales() {
            this.$emit("showSales");
        },
        selectCustomer(customer) {
            this.$emit("selectCustomer", customer);
        },
        chooseCustomer() {
            this.$emit("chooseCustomer");
        },
        searchCustomers(event) {
            clearTimeout(this.timer); // Clear previous timer
            this.timer = setTimeout(() => {
                const inputValue = event.target.value;
                this.$emit("searchCustomers", inputValue);
            }, 500);
        },
        navigateUpdateCustomer() {
            router.push("/customer/" + this.selectedCustomer.id + "/update")
        },
        selectInput() {
            if (this.posConfig.posConfig.showKeyboard == 1) {
                this.modalKeyboard.active = true;
            }
        },
        closePopupKeyboard(text) {
            const inputValue = document.getElementById("searchBarCustomer");
            inputValue.value = text;
            this.$emit("searchCustomers", inputValue.value);
            this.modalKeyboard.active = false;
        }
    }
};
</script>