<template>
    <div v-if="cashDrawer.opening_amount" class="grid grid-cols-3 gap-x-5 my-2 text-left">
        <a-card class="drop-shadow-md">
            <div class="flex flex-col items-start justify-between">
                <span>{{ $t('Pos.ODAmount') }}</span>
                <span class="font-semibold" :class="isPortrait ? 'text-2xl' : 'text-3xl'">{{ formatAmount(cashDrawer.opening_amount, currencyCode, decimalPrecision) }}</span>
            </div>
        </a-card>
        <a-card class="drop-shadow-md">
            <div class="flex flex-col items-start">
                <span>{{ $t('Pos.CPSale') }}</span>
                <span class="text-confirmation font-semibold" :class="isPortrait ? 'text-2xl' : 'text-3xl'">{{ formatAmount(cashDrawer.closing_amount-cashDrawer.opening_amount, currencyCode, decimalPrecision) }}</span>
            </div>
        </a-card>
        <a-card class="drop-shadow-md">
            <div class="flex flex-col items-start">
                <span>{{ $t('Pos.OPSale') }}</span>
                <span class="text-confirmation font-semibold" :class="isPortrait ? 'text-2xl' : 'text-3xl'">{{ formatAmount(cashDrawer.total_other_methods, currencyCode, decimalPrecision) }}</span>
            </div>
        </a-card>
    </div>
    <div class="flex mb-4 items-center justify-between">
        <span class="text-xl font-bold">
            {{ $t('Pos.SaleHistory') }}
        </span>
        <div class="flex items-end">
            <a-button @click="chooseCustomer"
                class="text-primary py-4 bg-white flex items-center border-primary font-semibold border-2">
                <FilterOutlined />
                {{
                $t("Pos.Filters")
            }}
            </a-button>
        </div>
    </div>
    <div class="bg-white h-auto flex flex-col rounded-md px-4 py-3">
        <div class="mt-6">
            <a-table class="ant-table-striped" size="middle" :columns="columns" :data-source="salesToday" :pagination="false"
                @focus="test" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">

                <template #bodyCell="{ column, text, record }">
                    <div @click="selectSale(record)">
                        <template v-if="column.dataIndex === 'id'">
                            <a>{{ '#' + text }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'created_at'">
                            <a>{{ formatDate(text) }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'total'">
                            <a>{{ formatAmount(text, currencyCode, decimalPrecision) }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'notes'">
                            <a>{{ record?.payments[0]?.notes }}</a>
                        </template>
                    </div>
                </template>
            </a-table>
            <div class="flex justify-end mt-4">
                <a-pagination v-model:current="currentPage" :total="totalItems" @change="handlePageChange"
                    :default-page-size="perPage" :showSizeChanger=false />
            </div>

        </div>

    </div>
</template>

<script>
import { currency } from '@/_helpers';
import { FilterOutlined } from '@ant-design/icons-vue';
import moment from "moment/moment";

export default {
    name: "LbrxTodaysSales",
    components: {
        FilterOutlined
    },
    data() {
        return {
            columns: [
                {
                    title: this.$t('Pos.OrderId'),
                    dataIndex: 'id',
                },
                {
                    title: this.$t('Pos.Date'),
                    dataIndex: 'created_at',
                },
                {
                    title: this.$t('Pos.TotalSales'),
                    dataIndex: 'total',
                },
                {
                    title: this.$t('Pos.DrawerNote'),
                    dataIndex: 'notes',
                },
            ],
            currentPage: 1,
        };
    },
    props: {
        salesToday: {
            required: true,
            value: Array
        },
        cashDrawer: {
            required: true,
            value: Object
        },
        totalItems: {
            required: true,
            value: Number
        },
        perPage: {
            required: true,
            value: Number
        },
        currencyCode: {
            required: false,
            value: String
        },
        decimalPrecision: {
            required: false,
            value: Number,
            default: 3
        },
        isPortrait: {
            required: true,
            value: Boolean
        }
    },
    methods: {
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        selectOrder(item) {
            this.$emit("selectOrder", item)
        },
        formatDate(date) {
            return moment(date).locale(this.$i18n.locale == 'ar' ? 'ar-tn' : this.$i18n.locale).format('LLLL')
        },
        selectSale(item) {
            this.$emit("selectSale", item)
        },
        searchSales(event) {
            const inputValue = event.target.value;
            this.$emit("searchSales", inputValue);
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.$emit("handlePageChange", page);
        }
    }
};
</script>

<style>

</style>