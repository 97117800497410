import {authHeader, ApiConfigs} from "../_helpers";

export const saleService = {
    getAll,
    getByCategory,
    getById,
    filter,
    create,
    delete: _delete,
    update,
    proceedSale,
    markAsSale
};

function getAll(current_page: string, per_page: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.getAll}?per_page=${per_page}&page=${current_page}`, requestOptions)
        .then(
            handleResponse
        );
}

function getByCategory(param: string, current_page: string, per_page: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.getAll}?per_page=${per_page}&page=${current_page}${param}`, requestOptions)
        .then(
            handleResponse
        );
}


function proceedSale(payload:object) {
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.proceedSale}`, requestOptions)
        .then(
            handleResponse
        );
}

function markAsSale(id: string) {
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.markAsSale.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function filter(filter: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.sale.getAll}?per_page=20&current_page=1&customer=${filter}`, requestOptions).then(handleResponse);
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sale.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function _delete(id: string, param: any = ""){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.sale.delete.replace(':id', id)+param}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.sale.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}


function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}