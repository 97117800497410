<template>
    <div class="flex flex-col flex-grow mx-4" :class="isPortrait ? 'h-[50vh] mb-4' : 'w-[58%]'">
        <div class="flex my-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ $t('Pos.AddCustomer') }}
            </span>
        </div>
        <div class="h-full overflow-hidden">
                <LbrxCreateCustomer @createCustomer="createCustomer" @cancelCreate="cancelCreate" :posConfig="posConfig" />
        </div>
    </div>
    <div v-if="!isPortrait" class="w-[32%]">
        <LbrxCart :cart="cart" @removeQuantity="removeQuantity" @addQuantity="addQuantity" @emptyCart="emptyCart" @showReceipt="showReceipt" 
        :hideDiscountBar="true" :currencyCode="currencyCode" :pos="pos" :posConfig="posConfig" />
    </div>
</template>
    
<script>
import LbrxCart from "@/components/LbrxCart.vue"
import LbrxCreateCustomer from "@/components/LbrxCreateCustomer.vue"
import { notification } from 'ant-design-vue';
import {notificationPlay} from '../../_helpers'
import { customerService } from '@/_services';
import router from "@/router";
export default {
    name: "addCustomer",
    components: {
        LbrxCart,
        LbrxCreateCustomer
    },
    data() {
        return {
            modal: {
                active: false
            },
            showAddCustomer: false,
            cart: [],
            customers: [],
            pos: {},
            posConfig: {},
            currency: {},
            currencyCode: {},
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => {return el.is_default == 1});
            if(this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
    },
    mounted() {
        this.getCustomers();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        getCustomers() {
            customerService.getAll().then((response) => {
                this.customers = response.data;
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        selectCustomer(item) {
            customerService.getAll().then((response) => {
                this.customers = response.data;
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        removeQuantity(product) {
            const index = this.findCartItem(product);
            if(this.cart[index].qty > 1) {
                this.cart[index].qty -= 1;
                notificationPlay.beep();
            } else {
                this.cart.splice(index, 1);
                notificationPlay.delete();
            }
        },
        addQuantity(product) {
            const index = this.findCartItem(product);
            this.cart[index].qty += 1;
            notificationPlay.beep();
        },
        emptyCart() {
            this.cart = [];
            notificationPlay.delete();
        },
        findCartIndex(product) {
            return this.cart.findIndex((p) => p.productId === product.id);
        },
        findCartItem(product) {
            return this.cart.findIndex((p) => p.productId === product.productId);
        },
        showReceipt() {
            this.modal.active = true;
        },
        hideReceipt() {
            this.modal.active = false;
        },
        createCustomer(customer) {
            customerService.create(customer).then((response) => {
                this.cancelCreate();
                this.showNotification(this.$t('Pos.CustomerCreated'));
            }).catch((error) => {
                console.log("error api : ", error);
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
            });
        },
        cancelCreate() {
            router.push("/customers")
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        }
    }
};
</script>

<style scoped>

</style>
    