<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[670px] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <span class="text-2xl my-8">{{ $t('Pos.TableReservation') }}</span>
      <div class="px-10">
        <a-select v-model:value="value" show-search placeholder="Select a person" class="w-full" :options="options"
          :filter-option="filterOption" @change="handleChange" @search="search" :field-names="{ label: 'full_name', value: 'id' }"></a-select>
      </div>
    </div>
  </div>
</template>

<script>
import { customerService } from '@/_services';
export default {
  name: "LbrxCustomizePopup",
  components: {
  },
  data() {
    return {
      activeKey: '0',
      options: [],
      value: undefined
    };
  },
  props: {
    selectedTable: {
      required: true,
      value: Object
    },
    active: {
      required: true,
      value: Boolean
    }
  },
  computed: {
    productPriceFormat() {
      return this.selectedItem.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    additionalIngrediants() {
      return this.checkedItems.map((el) => {
        return {
          id: el.id,
          qte: 1
        }
      });
    }
  },
  methods: {
    searchCustomers(filter) {
      customerService.filter("name", filter).then((response) => {
        this.options = response.data.map((el) => {
                return {
                    ...el,
                    full_name: el.first_name+" "+el.last_name
                }
            });
      }).catch((error) => {
        console.log("error api : ", error);
      });
    },
    handleChange(value) {
      console.log('sdfdsfds', value)
      // this.searchCustomers(value);
    },
    search(value) {
      if(value.length > 0) {
        this.searchCustomers(value);
      }
    },
    filterOption(input, option) {
      return option.first_name;
    },
    closePopup() {
      this.reset();
      this.$emit("closePopup")
    },
    reset() {
      this.checkedItems = [];
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmItem() {
      this.$emit("confirmItem", this.additionalIngrediants)
    },
    selectItem(item) {
      const index = this.findItemIndex(item);
      if (index === -1) {
        this.checkedItems.push(item);
      } else {
        this.checkedItems = this.checkedItems.filter(function (el) {
          return el !== item;
        });
      }
    },
    findItemIndex(item) {
      return this.checkedItems.findIndex((el) => el === item);
    },
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>