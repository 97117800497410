<template>
    <div class="w-screen h-screen flex flex-col">
        <a-config-provider :theme="{
            token: {
                colorPrimary: themeTemplate.primaryColor,
            },
        }"></a-config-provider>
        <LbrxHeader v-if="!(isPortrait && route.currentRoute.name == 'Checkout')" :themeTemplate="themeTemplate" :onLine="onLine" :posConfig="posConfig" :isPortrait="isPortrait" />
        <div class="flex flex-grow bg-[#ebeef0] overflow-hidden" :class="isPortrait ? 'flex-col' : ''">
            <div v-if="showSidebar" :class="isPortrait ? '' : 'w-[5rem]'">
                <LbrxSideMenu :currentRoute="route.currentRoute.name" :isPortrait="isPortrait" />
            </div>
            <div class="w-[100%]" :class="isPortrait ? 'flex flex-col h-full' : 'flex'">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import LbrxSideMenu from "@/components/LbrxSideMenu.vue"
import LbrxHeader from "@/components/LbrxHeader.vue"
import { notificationPlay } from '../../_helpers'
import { useRouter } from 'vue-router'
import { currencyService, printerService } from "@/_services"
import webSocketService from "@/_services/webSocketService"

export default {
    name: "posIndex",
    inject: ['echo'],
    components: {
        LbrxSideMenu,
        LbrxHeader
    },
    data() {
        return {
            modal: {
                active: false
            },
            isPortrait: window.matchMedia("(orientation: portrait)").matches,
            products: [
                {
                    "id": 1,
                    "name": "Beef Burger",
                    "price": 45000,
                    "image": "img/beef-burger.png",
                    "options": null
                },
                {
                    "id": 2,
                    "name": "Sandwich",
                    "price": 32000,
                    "image": "img/sandwich.png",
                    "options": null
                },
                {
                    "id": 3,
                    "name": "Sawarma",
                    "price": 30000,
                    "image": "img/sawarma.png",
                    "options": null
                },
                {
                    "id": 4,
                    "name": "Croissant",
                    "price": 16000,
                    "image": "img/croissant.png",
                    "options": null
                },
                {
                    "id": 5,
                    "name": "Cinnamon Roll",
                    "price": 20000,
                    "image": "img/cinnamon-roll.png",
                    "options": null
                },
                {
                    "id": 6,
                    "name": "Choco Glaze Donut with Peanut",
                    "price": 10000,
                    "image": "img/choco-glaze-donut-peanut.png",
                    "options": null
                },
                {
                    "id": 7,
                    "name": "Choco Glazed Donut",
                    "price": 10000,
                    "image": "img/choco-glaze-donut.png",
                    "options": null
                },
                {
                    "id": 8,
                    "name": "Red Glazed Donut",
                    "price": 10000,
                    "image": "img/red-glaze-donut.png",
                    "options": null
                },
                {
                    "id": 9,
                    "name": "Iced Coffee Latte",
                    "price": 25000,
                    "image": "img/coffee-latte.png",
                    "options": null
                },
                {
                    "id": 10,
                    "name": "Iced Chocolate",
                    "price": 20000,
                    "image": "img/ice-chocolate.png",
                    "options": null
                },
                {
                    "id": 11,
                    "name": "Iced Tea",
                    "price": 15000,
                    "image": "img/ice-tea.png",
                    "options": null
                },
                {
                    "id": 12,
                    "name": "Iced Matcha Latte",
                    "price": 22000,
                    "image": "img/matcha-latte.png",
                    "options": null
                }
            ],
            cart: [],
            themeTemplate: {
                primaryColor: "#fc8019",
                primaryLight: "#fff2e8",
                confirmationColor: "#09aa29",
                cart: {
                    selectCartItemColor: "#09aa29"
                }
            },
            route: null,
            onLine: navigator.onLine,
            pos: {},
            posConfig: {},
            socket: null
        };
    },
    created() {
        this.getThemeTemplate(JSON.parse(window.localStorage.getItem('themeTemplate')));
        this.route = useRouter();
        window.addEventListener('online', this.updateOnlineStatus)
        window.addEventListener('offline', this.updateOnlineStatus)
        this.getAllCurrencies();
        this.getAllPrinters();
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        this.updateDeviceOrientation(this.isPortrait);
    },
    mounted() {
        this.connectPusherRefresh();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
        this.socket = webSocketService.getSocket();
        setTimeout(() => {
            this.sendMessage();
        }, 800);
        
    },
    computed: {
        showSidebar() {
            return this.route.currentRoute.name == "Checkout" ? false : true
        }
    },
    watch: {
        onLine(v) {
            if (v) {
                location.reload()
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('online', this.updateOnlineStatus)
        window.removeEventListener('offline', this.updateOnlineStatus)
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    methods: {
        extractDefaultCurrency(array) {
            return array.find(item => item.is_default == 1);
        },
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
            this.updateDeviceOrientation(this.isPortrait);
        },
        updateDeviceOrientation(status) {
            localStorage.setItem("orientation", status)
        },
        updateOnlineStatus(e) {
            const { type } = e
            this.onLine = type === 'online'
        },
        getThemeTemplate(theme) {
            this.themeTemplate.primaryColor = theme.primaryColor;
            this.themeTemplate.primaryLight = theme.primaryLight;
            this.themeTemplate.confirmationColor = theme.confirmationColor;
            this.themeTemplate.cart.selectCartItemColor = theme.cart.selectCartItemColor;

            const customStyles = `
            .bg-primary {
                background-color: ${this.themeTemplate.primaryColor};
            }
            .bg-primary-light {
                background-color: ${this.themeTemplate.primaryLight};
            }
            .text-primary {
                color: ${this.themeTemplate.primaryColor};
            }
            .border-primary {
                border-color: ${this.themeTemplate.primaryColor};
            }
            .outline-primary {
                outline-color: ${this.themeTemplate.primaryColor};
            }
            .bg-confirmation {
                background-color: ${this.themeTemplate.confirmationColor};
            }
            .text-confirmation {
                color: ${this.themeTemplate.confirmationColor};
            }
            .accent-primary {
                accent-color: ${this.themeTemplate.primaryColor};
            }
            `;

            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';

            // Add your custom styles to the <style> element
            if (styleElement.styleSheet) {
                // For Internet Explorer
                styleElement.styleSheet.cssText = customStyles;
            } else {
                // For other browsers
                styleElement.appendChild(document.createTextNode(customStyles));
            }

            // Append the <style> element to the <head> of the document
            document.head.appendChild(styleElement);
        },
        addToCart(product) {
            const index = this.findCartIndex(product);
            if (index === -1) {
                this.cart.push({
                    productId: product.id,
                    image: product.image,
                    name: product.name,
                    price: product.price,
                    option: product.option,
                    qty: 1,
                });
            } else {
                this.cart[index].qty += 1;
            }
            notificationPlay.beep();
            // this.beep();
            // this.updateChange();
        },
        removeQuantity(product) {
            const index = this.findCartItem(product);
            if (this.cart[index].qty > 1) {
                this.cart[index].qty -= 1;
                notificationPlay.beep();
            } else {
                this.cart.splice(index, 1);
                notificationPlay.delete();
            }
        },
        addQuantity(product) {
            const index = this.findCartItem(product);
            this.cart[index].qty += 1;
            notificationPlay.beep();
        },
        emptyCart() {
            this.cart = [];
            notificationPlay.delete();
        },
        findCartIndex(product) {
            return this.cart.findIndex((p) => p.productId === product.id);
        },
        findCartItem(product) {
            return this.cart.findIndex((p) => p.productId === product.productId);
        },
        showReceipt() {
            this.modal.active = true;
        },
        hideReceipt() {
            this.modal.active = false;
        },
        filteredProducts() {
            const rg = this.keyword ? new RegExp(this.keyword, "gi") : null;
            this.products.filter((p) => !rg || p.name.match(rg));
        },
        getAllCurrencies() {
            currencyService.getAll().then((res) => {
                const currdefault = this.extractDefaultCurrency(res.data);
                localStorage.setItem("currencies", JSON.stringify(res.data));
                localStorage.setItem("default_currency", JSON.stringify(currdefault));
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        getAllPrinters() {
            printerService.getAll().then((res) => {
                localStorage.setItem("printer_config", JSON.stringify(res));
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socket = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socket.onopen = (event) => {
                this.socket.send(token); // send the token as soon as the connection is established
            };

            this.socket.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socket.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socket.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socket.readyState === WebSocket.OPEN) {
                const message = {
                    action: "setup",
                    data: JSON.parse(localStorage.getItem("device")),
                    logo: JSON.parse(localStorage.getItem("user")).store.logo
                };
                this.socket.send(JSON.stringify(message));
                this.loadingPrinting = false;
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        },
        connectPusherRefresh() {
            this.echo.channel("device-" + this.pos.id + "-" + JSON.parse(localStorage.getItem("user")).store.uid).listen(
                ".device-refresh",
                function (res) {
                    console.log("listening to refresh_channel");
                    localStorage.setItem("device", JSON.stringify(res.device));
                    this.setThemeTemplate(JSON.parse(res.device.config).store);
                    location.reload();
                }.bind(this)
            );

            this.echo.channel("device-" + this.pos.id + "-" + JSON.parse(localStorage.getItem("user")).store.uid).listen(
                ".device-logout",
                function (res) {
                    console.log("listening to logout_channel");
                    this.echo.leaveChannel("device-" + this.pos.id + "-" + JSON.parse(localStorage.getItem("user")).store.uid);
                    localStorage.removeItem("user");
                    localStorage.removeItem("user_language");
                    localStorage.removeItem("cart");
                    localStorage.removeItem("sale");
                    localStorage.removeItem("device");
                    localStorage.removeItem("themeTemplate");
                    location.reload();
                }.bind(this)
            );
        },
        setThemeTemplate(theme) {
            const themeTemplate = {
                primaryColor: theme.primaryColor,
                primaryLight: theme.primaryLight,
                secondaryColor: theme.secondaryColor,
                confirmationColor: "#09aa29",
                cart: {
                    selectCartItemColor: "#09aa29"
                }
            }
            localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
        },
    }
};
</script>

<style scoped></style>