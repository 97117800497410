<template>
    <div class="bg-[#f1f1f1] flex flex-col w-screen h-screen">
        <LbrxLoaderBlur v-if="showLoader" />
        <div class="flex items-center justify-between xl:px-14 px-8 py-7" :style="`background-color: ${primaryColor};`">

            <div class="flex flex-col text-white text-left font-semibold">
                <span class="xl:text-lg text-base">{{ $t('Pos.LicensedTo') }}</span>
                <span class="xl:text-2xl text-lg">{{ posConfig.store.name }}</span>
            </div>
            <div class="absolute left-1/2 transform -translate-x-1/2 text-white font-semibold text-2xl">
                <img src="/posys-logo-white.png" class="w-48" alt="">
            </div>
            <div class="grid grid-cols-3 gap-3 text-white text-sm">
                <div class="bg-gray-400 xl:p-4 p-1 rounded-md flex flex-col items-center"
                    :class="selectedAction == 'checkin' ? 'bg-gray-700' : ''" :style="isPortrait ? 'padding: 13px' : ''"
                    @click="selectAction('checkin')">
                    <iconLogin :size="28" :color="'#fff'" /><span v-if="!isPortrait">{{ $t('Pos.Checkin') }}</span>
                </div>
                <div class="bg-gray-400 xl:py-4 xl:px-6 p-1 rounded-md flex flex-col items-center"
                    :class="selectedAction == 'shutdown' ? 'bg-gray-700' : ''"
                    :style="isPortrait ? 'padding: 13px' : ''" @click="selectAction('shutdown')">
                    <iconLogout :size="28" :color="'#fff'" /><span v-if="!isPortrait">{{ $t('Pos.Shutdown') }}</span>
                </div>
                <div class="bg-gray-400 xl:py-2 xl:px-3 p-1 rounded-md flex flex-col items-center justify-center"
                    :class="selectedAction == 'reboot' ? 'bg-gray-700' : ''" :style="isPortrait ? 'padding: 13px' : ''"
                    @click="selectAction('reboot')">
                    <iconRefresh :size="28" :color="'#fff'" /><span v-if="!isPortrait">{{ $t('Pos.Reboot') }}</span>
                </div>
            </div>
        </div>
        <div class="flex flex-grow justify-center" :class="isPortrait ? 'flex-col items-center' : ''">
            <div v-if="posConfig.store.logo" class="w-1/2 flex items-center justify-center">
                <img class="" :src="posConfig.store.logo" alt="">
            </div>

            <div class="w-1/2 flex items-center justify-center px-14 py-8">
                <!-- <LbrxUserSelect v-if="!selectedUser.fname" :users="users" :selectedUser="selectedUser"
                    @selectUser="selectUser" /> -->
                <LbrxLoginPad :pinCode="pinCode" @clickPad="clickPad" @checkInput="checkInput"
                    :isPortrait="isPortrait" />
            </div>
        </div>
        <div class="flex items-center gap-x-5 px-10">
        </div>
    </div>
    <LbrxRebootPopup :active="modal.active" @closePopup="closePopup" :primaryColor="primaryColor" :message="message"
        @confirm="confirm" />
</template>

<script>
import LbrxLoginPad from "@/components/LbrxLoginPad.vue"
// import LbrxUserSelect from "@/components/LbrxUserSelect.vue"
import iconLogin from "@/components/icons/iconLogin.vue"
import iconRefresh from "@/components/icons/iconRefresh.vue"
import iconLogout from "@/components/icons/iconLogout.vue"
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import LbrxRebootPopup from "@/components/LbrxRebootPopup.vue"
import { notification } from 'ant-design-vue';
import { authService } from "@/_services"
import router from "@/router";
import webSocketService from "@/_services/webSocketService"
export default {
    name: "loginPage",
    components: {
        LbrxLoginPad,
        // LbrxUserSelect,
        iconLogin,
        iconRefresh,
        iconLogout,
        LbrxLoaderBlur,
        LbrxRebootPopup
    },
    data() {
        return {
            isDisabled: false,
            selectedUser: {
                fname: null,
                lname: null
            },
            users: [
                { fname: "Jim", lname: "Cox" },
                { fname: "Mary", lname: "Goldberg" },
                { fname: "Nikkie", lname: "Walenstein" },
                { fname: "Jerry", lname: "Coldalley" },
            ],
            modal: {
                active: false
            },
            pinCode: "",
            selectedAction: "checkin",
            pos: {},
            posConfig: {},
            primaryColor: JSON.parse(localStorage.getItem('themeTemplate')).primaryColor,
            showLoader: false,
            socketAction: "",
            message: "",
            socketDisplay: null,
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
    },
    mounted() {
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
        this.socketDisplay = webSocketService.getSocket();
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        selectUser(user) {
            this.selectedUser = user;
        },
        selectAction(action) {
            this.selectedAction = action;
        },
        login(method = "pin") {
            if (method == "pin") {
                this.showLoader = true;
                let payload = {
                    code: this.pinCode
                }
                authService.loginByPin(payload).then((res) => {
                    if (res.status == 'ok') {
                        localStorage.setItem('user_language', 'fr')
                        this.$i18n.locale = localStorage.getItem('user_language')
                        if (this.posConfig.store.defaultRoute) {
                            router.push(this.posConfig.store.defaultRoute);
                        } else {
                            router.push("/home");
                        }
                        // location.reload();
                    }
                }).catch((err) => {
                    this.showErrorNotification(this.$t('Pos.ErrorLoginPin'))
                    console.log("err", err)
                }).finally(() => {
                    this.pinCode = "";
                    this.showLoader = false;
                })
            }
        },
        checkPincode() {
            this.showLoader = true;
            let payload = {
                code: this.pinCode
            }
            authService.loginByPin(payload).then((res) => {
                if (res.status == 'ok') {
                    this.modal.active = true;
                }
            }).catch((err) => {
                this.showErrorNotification(this.$t('Pos.ErrorLoginPin'))
                console.log("err", err)
            }).finally(() => {
                this.pinCode = "";
                this.showLoader = false;
            })
        },
        clickPad(num) {
            if (this.isDisabled)
                return;
            if (num === -1) {
                this.pinCode = "";
            } else if (num === -2) {
                this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1);
            } else {
                if (this.pinCode.length < 6)
                    this.pinCode += num;
                if (this.pinCode.length == 6) {
                    if (this.selectedAction == "shutdown") {
                        this.socketAction = this.selectedAction;
                        this.message = this.$t('Pos.DeviceShutdown');
                        this.checkPincode();
                    } else if (this.selectedAction == "reboot") {
                        this.socketAction = this.selectedAction;
                        this.message = this.$t('Pos.DeviceReboot');
                        this.checkPincode();
                    } else {
                        this.login("pin")
                    }
                }
            }
        },
        checkInput(input) {
            if (input.length <= 6) {
                this.pinCode = input;
                if (this.pinCode.length == 6) {
                    if (this.selectedAction == "shutdown") {
                        this.socketAction = this.selectedAction;
                        this.message = this.$t('Pos.DeviceShutdown');
                        this.checkPincode();
                    } else if (this.selectedAction == "reboot") {
                        this.socketAction = this.selectedAction;
                        this.message = this.$t('Pos.DeviceReboot');
                        this.checkPincode();
                    } else {
                        this.login("pin")
                    }
                }
            }
        },
        closePopup() {
            this.modal.active = false;
        },
        confirm() {
            this.sendMessage();
            this.closePopup();
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socketDisplay = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socketDisplay.onopen = (event) => {
                this.socketDisplay.send(token); // send the token as soon as the connection is established
            };

            this.socketDisplay.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socketDisplay.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
            };

            this.socketDisplay.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socketDisplay.readyState === WebSocket.OPEN) {
                const message = {
                    action: this.socketAction
                };
                this.socketDisplay.send(JSON.stringify(message));
                if (this.socketAction == "reboot")
                    this.showNotification(this.$t('Pos.RebootSuccess'));
                else
                    this.showNotification(this.$t('Pos.ShutdownSuccess'));
            } else {
                console.error("Socket is not open. Can't send message");
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
            }
        }
    }
};
</script>