<template>
  <div class="flex rounded-lg justify-between h-min" :style="getDynamicStyle">
    <a-collapse :disabled="true" v-model:activeKey="activeKey" :bordered="false" class="w-full text-left font-semibold"
      style="background: none">
      <a-collapse-panel :key="index" :header="item.quantity + '&nbsp;&nbsp;&nbsp;'+item.product?.name">
        <template #extra>
          <div class="flex items-center gap-3"><span>{{ parseFloat(item.total).toFixed(decimalPrecision) }}</span>
            <div class="bg-gray-400 w-fit h-fit rounded-full p-[3px]" @click.stop="removeQuantity(item)">
              <iconCloseFill class="opacity-90" :size="12" :color="'#fff'" />
            </div>
          </div>
        </template>
        <div class="flex flex-col justify-around gap-1 ml-6 mr-7 font-semibold text-[#999999]">
          <div v-for="(item, index) in item.additional_ingredients?.ingredients" :key="index"
            class="flex justify-between" :class="item.included_by_default != 0 ? 'hidden' : ''">
            <div class="flex gap-3">
              <span>{{ item.quantity }}</span>
              <span>{{ item.name }}</span>
            </div>
            <span>{{ parseFloat(item.total).toFixed(decimalPrecision) }}</span>
          </div>
          <div v-for="(item, index) in item.additional_preferences?.preferences" :key="index"
            class="flex justify-left">
            <div class="flex gap-3">
              <span>-</span>
              <span>{{ item.preference }}</span>
            </div>
          </div>
          <div v-for="(item, index) in item.product_variant?.attribute_values" :key="index"
            class="flex justify-left">
            <div class="flex gap-3">
              <span>-</span>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!-- <div class="bg-[#f5f5f5] rounded-lg flex w-full justify-between py-3 pl-10 pr-4 font-semibold text-sm" v-else>
      <div class="flex gap-3">
        <span>{{ item.quantity+"x" }}</span>
        <span>{{ item.product?.name }}</span>
      </div>
      <div>
        <div class="flex items-center gap-3"><span>{{ productPriceFormat(item.total) }}</span>
          <div class="bg-gray-400 w-fit h-fit rounded-full p-0.5" @click.stop="removeQuantity(item)">
            <iconCloseFill class="opacity-90" :size="12" :color="'#fff'" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { notificationPlay } from '../_helpers'
import iconCloseFill from "./icons/iconCloseFill.vue"
export default {
  name: "LbrxCartItem",
  components: {
    iconCloseFill
  },
  data() {
    return {
      itemQuantity: 1,
      activeKey: [],
      test: 'test'
    };
  },
  props: {
    item: {
      required: true,
      value: Object
    },
    bgColor: {
      required: false,
      value: String
    },
    index: {
      required: true,
      value: Number
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    }
  },
  computed: {
    getDynamicStyle() {
      return {
        backgroundColor: this.bgColor,
        borderLeft: this.index == this.activeKey[0] ? '3px solid #31ac12' : ''
      }
    }
  },
  methods: {
    addQuantity(product) {
      this.$emit('addQuantity', product);
    },
    removeQuantity(product) {
      this.$emit('removeQuantity', product);
    },
    productPriceFormat(number) {
      var strNumber = number.toString();

      // Split the number at the decimal point
      var parts = strNumber.split('.');

      // Check if there is a fractional part
      if (parts.length === 2) {
        // Check the length of the fractional part
        var fractionalLength = parts[1].length;

        // Add zeros to the fractional part if needed
        while (fractionalLength < 3) {
          parts[1] += '0';
          fractionalLength++;
        }

        // Join the parts back together and return the result
        return parts.join('.');
      } else {
        // If there is no fractional part, add '.000'
        return strNumber + '.000';
      }
    },
  }



};
</script>

<style scoped></style>