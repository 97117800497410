<template>
    <div class="relative flex flex-col h-full w-full px-4 overflow-y-auto">
        <LbrxLoaderBlur v-if="showLoader" />
        <!--search card-->
        
        <div :class="isPortrait ? 'grid-cols-2 grid-rows-2' : 'grid grid-cols-3 gap-3'">
            
            <div >
                <!-- <LbrxFilterBar id="filter1" :filters="filterPeriod" :selectedFilter="selectedFilterPeriod"
                    @selectFilter="selectFilter" :fitWidth="false" /> -->
                    <LbrxFilterBar :filters="filtersData" :selectedFilter="selectedFilterData" @selectFilter="selectFilterData"
            :fitWidth="false" :margin="'15px 0 15px auto'" class="mt-2" :title="$t('Pos.FilterBy')" />
            </div>
            <div >
                <!-- <LbrxFilterBarDate id="filter1" @selectFilterInterval="selectFilterInterval" :fitWidth="false" /> -->
                <LbrxFilterBarDate id="filter2" @selectFilterdate="selectFilterdate" @selectFilterInterval="selectFilterInterval" :fitWidth="false" :type="'range'" />
            </div>
            <div class="flex gap-3" :class="isPortrait ? 'col-span-2 row-start-2 mb-[18px]' : 'my-[18px]'">
            <button
                class="flex px-2 py-3 w-full rounded-md bg-[#09aa29] items-center justify-center text-sm  text-white whitespace-nowrap"
                @click="refreshByDate()">
                <span class="text-lg">{{  $t('Pos.RefreshDate') }}</span>
            </button>
            <button
                class="flex px-2 py-3 w-full rounded-md items-center justify-center text-sm  text-white whitespace-nowrap"
                :class="!loadingPrinting ? 'bg-primary' : 'bg-[#bebebe]'" @click="selectFilterdate()">
                <span v-if="!loadingPrinting" class="text-lg">{{ $t('Pos.PrintReport') }}</span>
                <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
            </button>
        </div>

            
        </div>

        <div>
            <div class="flex flex-row gap-3 w-full">
                <div class="p-4 bg-white flex flex-col gap-5 text-left rounded-md" :class="isPortrait ? 'w-full' : 'basis-4/12'">
                    <div><span class="text-primary">{{ $t('Pos.TotalSales') }}</span>
                        <DollarCircleOutlined class="mx-2" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <div class="flex justify-between"><span class="flex-grow">{{ $t('Pos.Today') }}: </span>
                            <span class="font-semibold">{{ formatAmount(statisticsSales.today_total_sales, currencyCode,
                                decimalPrecision)
                                }}</span>
                        </div>
                        <div class="flex justify-between"><span class="flex-grow">{{ $t('Pos.CurrentMonth') }}: </span>
                            <span class="font-semibold">{{
                                formatAmount(statisticsSales.current_month_sales, currencyCode, decimalPrecision)
                            }}</span>
                        </div>
                        <div class="flex justify-between"><span class="flex-grow">{{ $t('Pos.LastMonth') }}: </span>
                            <span class="font-semibold">{{
                                formatAmount(statisticsSales.previous_month_sales, currencyCode, decimalPrecision)
                                }}</span>
                        </div>
                    </div>
                </div>

                <div class="p-4 bg-white flex flex-col gap-5 text-left rounded-md" :class="isPortrait ? 'w-full' : 'basis-4/12'">
                    <div><span class="text-primary">{{ $t('Pos.SalesEfficiency') }}</span>
                        <RiseOutlined :style="{ fontSize: '22px', color: '#09aa29', margin: '0 10px' }"
                            v-if="checkEfficiency" />
                        <FallOutlined :style="{ fontSize: '22px', color: '#E72929', margin: '0 10px' }" v-else />
                    </div>
                    <div class="flex flex-col gap-2">
                        <div class="flex justify-between items-center"><span class="flex-grow">{{ $t('Pos.CurrentMonth')
                                }}:
                            </span> <span class="font-semibold"
                                :class="checkEfficiency ? 'text-[#09aa29]' : 'text-[#E72929]'">
                                <CaretUpOutlined :style="{ fontSize: '22px', color: '#09aa29', marginRight: '5px' }"
                                    v-if="checkEfficiency" />
                                <CaretDownOutlined :style="{ fontSize: '22px', color: '#E72929', marginRight: '5px' }"
                                    v-else />{{ statisticsSales.currentMonthEfficiency?.toFixed(decimalPrecision) }}
                            </span><span class="ml-2 font-semibold text-confirmation">
                                <iconAnalytic :size="18" :color="'#141B34'" />
                            </span></div>
                        <div class="flex justify-between items-center"><span class="flex-grow">{{ $t('Pos.LastMonth')
                                }}:
                            </span>
                            <span class="font-semibold">{{
                                statisticsSales.previousMonthEfficiency?.toFixed(decimalPrecision) }}</span><span
                                class="ml-2 font-semibold text-confirmation">
                                <iconAnalytic :size="18" :color="'#141B34'" />
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="!isPortrait" class="p-4 basis-2/12 flex flex-col gap-5 text-left rounded-md">
                    <!-- <div>
                        <span class="text-primary">{{ $t('Pos.TotalProductsS') }}</span>
                    </div>
                    <div class="flex justify-center gap-6 h-full items-center pb-7">
                        <div class="text-6xl font-bold">
                            {{ statisticsProducts?.products_sold?.length }}
                        </div>
                        <div class="bg-[#6AD4DD] rounded-full p-3">
                            <ShoppingCartOutlined :style="{ fontSize: '30px', color: '#fff' }" />
                        </div>
                    </div> -->
                </div>

                <div v-if="!isPortrait" class="p-4 basis-2/12 flex flex-col gap-5 text-left rounded-md">
                    <!-- <div>
                        <span class="text-primary">{{ $t('Pos.TotalProductsS') }}</span>
                    </div>
                    <div class="flex justify-center gap-6 h-full items-center pb-7">
                        <div class="text-6xl font-bold">
                            {{ statisticsProducts?.products_sold?.length }}
                        </div>
                        <div class="bg-[#6AD4DD] rounded-full p-3">
                            <ShoppingCartOutlined :style="{ fontSize: '30px', color: '#fff' }" />
                        </div>
                    </div> -->
                </div>

            </div>
            <div class="my-6 grid  gap-4">
                <a-card class="w-full" v-if="show">
                    <Line :data="data" style="height: 00px; width: 100%; margin: 0 auto;" :options="options" />
                </a-card>

            </div>
            <div class="flex flex-row gap-3 mb-8">
                <a-card class="basis-6/12 text-left">
                    <span class="text-xl font-semibold">{{ $t('Pos.ProductsSold') }}</span>
                    <a-table class="my-4" size="middle" :columns="columns" :data-source="statisticsCashier.products"
                        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="false">
                        <template #bodyCell="{ column, text, record }">
                            <template v-if="column.dataIndex === 'quantity'">
                                <span class="font-semibold mr-2">{{ text }}</span>
                                <span>{{ record.unit }}</span>
                            </template>
                            <template v-if="column.dataIndex === 'total'">
                                <span class="text-[#09aa29] font-semibold mr-2">{{ formatAmount(record.total,
                                    currencyCode, decimalPrecision) }}</span>
                            </template>
                        </template>
                    </a-table>
                </a-card>
                <a-card class="basis-6/12 text-left">
                    <span class="text-xl font-semibold">{{ $t('Pos.SalesCategory') }}</span>
                    <a-table class="my-4" size="middle" :columns="columnsCategory"
                        :data-source="statisticsCashier.categories"
                        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="false">
                        <template #bodyCell="{ column, text, record }">
                            <template v-if="column.dataIndex === 'quantity'">
                                <span class="font-semibold mr-2">{{ text }}</span>
                                <span>{{ record.unit }}</span>
                            </template>
                            <template v-if="column.dataIndex === 'total'">
                                <span class="text-[#09aa29] font-semibold mr-2">{{ formatAmount(record.total,
                                    currencyCode, decimalPrecision) }}</span>
                            </template>
                        </template>
                    </a-table>
                </a-card>
            </div>
            <div class="flex flex-row gap-3 mb-8" :class="isPortrait ? 'mb-20' : ''">
                <a-card class="basis-6/12 text-left">
                    <span class="text-xl font-semibold">{{ $t('Pos.ProductsPopular10') }}</span>
                    <a-table class="my-4" size="middle" :columns="columns" :data-source="statisticsProducts?.products_sold"
                        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="false">
                        <template #bodyCell="{ column, text, record }">
                            <template v-if="column.dataIndex === 'quantity'">
                                <span class="font-semibold mr-2">{{ text }}</span>
                            </template>
                            <template v-if="column.dataIndex === 'total'">
                                <span class="text-[#09aa29] font-semibold mr-2">{{ formatAmount(record.total,
                                    currencyCode, decimalPrecision) }}</span>
                            </template>
                        </template>
                    </a-table>
                </a-card>
            </div>
        </div>
        <LbrxInfoPopup :active="modalInfo.active" @closePopup="closePopup" :type="modalInfo.type"
            :message="modalInfo.message" />
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
import {
    DollarCircleOutlined,
    FallOutlined,
    RiseOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    LoadingOutlined
} from '@ant-design/icons-vue';
import moment from "moment/moment";
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import LbrxFilterBarDate from "@/components/LbrxFilterBarDate.vue"
import LbrxInfoPopup from '@/components/LbrxInfoPopup.vue';
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import iconAnalytic from '@/components/icons/iconAnalytic.vue';
import { statisticService, cashDrawerService } from '@/_services';
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import { currency } from '@/_helpers';
import dayjs , { Dayjs }from 'dayjs';
import webSocketService from '@/_services/webSocketService';

export default defineComponent({
    name: "reportsIndex",
    components: {
        FallOutlined,
        RiseOutlined,
        LbrxFilterBar,
        LbrxFilterBarDate,
        DollarCircleOutlined,
        iconAnalytic,
        CaretUpOutlined,
        CaretDownOutlined,
        Line,
        LbrxLoaderBlur,
        LbrxInfoPopup,
        LoadingOutlined
    },
    data() {
        return {
            data: {
                labels: ['January', 'February'],
                datasets: [
                    {
                        label: this.$t('Pos.ProfitMade'),
                        backgroundColor: '#f87979',
                        data: ["34", "78"]
                    }
                ],
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            },
            selectedFilter: {},
            filtersData: [{ name: this.$t('Pos.Cashier'), id: 1 }, { name: this.$t('Pos.User'), id: 2 }, { name: this.$t('Pos.All'), id: 3 }],
            selectedFilterPeriod: {},
            selectedFilterData: {},
            selectedFilterInterval: [],
            selectedFilterDate: moment().format('YYYY-MM-DD'),
            modalReserve: {
                active: false
            },
            modalInfo: {
                active: false,
                type: "",
                message: ""
            },
            filterPeriod: [
                {
                    id: "day",
                    name: this.$t('Pos.Day')
                },
                {
                    id: "week",
                    name: this.$t('Pos.Week')
                },
                {
                    id: "month",
                    name: this.$t('Pos.Month')
                }
            ],
            columns: [
                {
                    title: this.$t('Pos.Product'),
                    dataIndex: 'name'
                },
                {
                    title: this.$t('Pos.QuantitySold'),
                    dataIndex: 'quantity',
                },
                {
                    title: this.$t('Pos.AmountSold'),
                    dataIndex: 'total',
                },
            ],
            columnsCategory: [
                {
                    title: this.$t('Pos.Category'),
                    dataIndex: 'name'
                },
                {
                    title: this.$t('Pos.QuantitySold'),
                    dataIndex: 'quantity',
                },
                {
                    title: this.$t('Pos.AmountSold'),
                    dataIndex: 'total',
                },
            ],
            tableLocations: [],
            statisticsSales: {},
            statisticsProducts: {},
            statisticsCashier: {},
            show: false,
            socket: null,
            showLoader: false,
            pos: {},
            posConfig: {},
            currency: {},
            currencyCode: {},
            drawerInfo: {},
            drawerOpened: false,
            loadingPrinting: false,
            NoOpenDrawer: false,
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
        this.getAllSales();
        this.getAllProducts();
        // this.getDrawerInfo();
        this.getCashierStatistics();
        this.selectedFilterData = this.filtersData[2];
    },
    mounted() {
        this.socket = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        checkEfficiency() {
            return (this.statisticsSales.currentMonthEfficiency - this.statisticsSales.previousMonthEfficiency) < 0 ? false : true;
        },
        decimalPrecision() {
            return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
        }
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        openPopupInfo(message) {
            this.modalInfo.active = true;
            this.modalInfo.message = message;
        },
        getAllSales() {
            this.showLoader = true;
            statisticService.getAllSales().then((res) => {
                console.log('fff, ', res.dailySalesPrevious30Days.map((el) => { return el.date }))
                this.statisticsSales = res;
                this.data.labels = res.dailySalesPrevious30Days.map((el) => { return el.date });
                this.data.datasets[0].data = res.dailySalesPrevious30Days.map((el) => { return el.total.toString() });
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.show = true
                this.showLoader = false;
            });
        },
        getAllProducts() {
            statisticService.getAllProducts("?warehouse_id=" + this.posConfig.warehouse_id).then((res) => {
                this.statisticsProducts = res;
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        getCashierStatistics(param = "?device_id=" + this.pos.id) {
            let param2 = this.selectedFilterInterval.length != 0 ? "&start_datetime=" + this.selectedFilterInterval[0] + "&end_datetime=" + this.selectedFilterInterval[1] : "&start_datetime=" + moment().format("YYYY-MM-DD 00:00:00") + "&end_datetime=" + moment().format("YYYY-MM-DD 23:59:00");
            statisticService.getCashierReport(param + param2).then((res) => {
                this.statisticsCashier = res;
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        getCashierStatisticsPrint(param = "?device_id=" + this.pos.id) {
            this.loadingPrinting = true;
            let param2 = this.selectedFilterInterval.length != 0 ? "&start_datetime=" + this.selectedFilterInterval[0] + "&end_datetime=" + this.selectedFilterInterval[1] : "&start_datetime=" + moment().format("YYYY-MM-DD 00:00:00") + "&end_datetime=" + moment().format("YYYY-MM-DD 23:59:00");
            statisticService.getCashierReport(param + param2).then((res) => {
                this.statisticsCashier = res;
                if (this.selectedFilterInterval.length == 0) {
                this.selectedFilterDate = moment().format('YYYY-MM-DD');
                this.getDrawerInfo();
            } else {
                // this.selectedFilterDate = moment(dateString).format('YYYY-MM-DD');
                this.getClosedDrawerInfo()
            }
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        closePopup() {
            this.modalInfo.active = false;
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socket = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socket.onopen = (event) => {
                this.socket.send(token); // send the token as soon as the connection is established
            };

            this.socket.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socket.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socket.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socket.readyState === WebSocket.OPEN) {
                let statObject = this.statisticsCashier;
                statObject.stock = this.statisticsProducts.stock;
                statObject.drawerInfo = {};
                // check if attributes aren't null
                statObject.drawerInfo.opening_amount = this.drawerInfo && this.drawerInfo.opening_amount ? parseFloat(this.drawerInfo.opening_amount) : parseFloat((0).toFixed(this.decimalPrecision));
                statObject.drawerInfo.total_cash_payment = (this.drawerInfo && this.drawerInfo.total_cash) ? parseFloat((this.drawerInfo.total_cash - this.drawerInfo.opening_amount).toFixed(this.decimalPrecision)) : parseFloat((0).toFixed(this.decimalPrecision));
                statObject.drawerInfo.total_other_methods = this.drawerInfo && this.drawerInfo.total_other_methods ? parseFloat(this.drawerInfo.total_other_methods) : parseFloat((0).toFixed(this.decimalPrecision));
                statObject.drawerInfo.total_cash = this.drawerInfo && this.drawerInfo.total_cash ? parseFloat(this.drawerInfo.total_cash) : parseFloat((0).toFixed(this.decimalPrecision));
                const message = {
                    action: "print_report",
                    data: statObject
                };
                this.socket.send(JSON.stringify(message));
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        },
        printReport() {
            // if (!this.drawerOpened) {
            //     statisticService.getCashierReport("?device_id=" + this.pos.id + "&date=" + this.selectedFilterDate).then((res) => {
            //         this.statisticsCashier = res;
            if (this.NoOpenDrawer) {
                this.modalInfo.active = true;
                this.modalInfo.type = "message";
                this.modalInfo.message = this.$t('Pos.NoOpenDrawer');
                this.loadingPrinting = false;
            } else {
                this.loadingPrinting = true;
                this.sendMessage();
                setTimeout(() => {
                    this.loadingPrinting = false;
                }, 2000);
            }

            //     }).catch((error) => {
            //         console.log("error api : get products", error);
            //     });
            // } else {
            //     this.openPopupInfo(this.$t('Pos.DrawerNotClose'))
            // }
        },
        selectFilterInterval(item) {
            if(item) {
                this.selectedFilterInterval = item.map((el) => { return dayjs(el).format("YYYY-MM-DD HH:mm") });
            } else {
                this.selectedFilterInterval = [];
            }
            
        },
        selectFilterdate(dateString) {
            this.getCashierStatisticsPrint();
        },
        getDrawerInfo() {
            cashDrawerService.getAll().then((res) => {
                this.NoOpenDrawer = false;
                this.drawerInfo = res.data[0];
                this.drawerInfo.total_cash_payment = (this.drawerInfo.total_cash - this.drawerInfo.opening_amount).toFixed(this.decimalPrecision);
                this.printReport();
            }).catch((error) => {
                console.log("error api : ", error);
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
            }).finally(() => {
                // 
            });
        },
        getClosedDrawerInfo() {
            cashDrawerService.getClosedDrawer("?start_datetime=" + this.selectedFilterInterval[0] + "&end_datetime=" + this.selectedFilterInterval[1]).then((res) => {
                if (res.message == 'No open cash drawer') {
                    this.NoOpenDrawer = true;
                } else {
                    this.NoOpenDrawer = false;
                }
                this.drawerInfo = res.data;
                this.drawerInfo.total_cash_payment = (this.drawerInfo.total_cash - this.drawerInfo.opening_amount).toFixed(this.decimalPrecision);
                this.printReport();
            }).catch((error) => {
                console.log("error api : ", error);
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
            }).finally(() => {
                // 
            });
        },
        refreshByDate() {
            this.getAllProducts();
            this.getCashierStatistics();
        },
        selectFilterData(item) {
            this.selectedFilterData = item;
            if (item.id == 1) {
                this.selectedParam = "?device_id=" + this.pos.id;
                this.getCashierStatistics(this.selectedParam)
            } else if (item.id == 2) {
                this.selectedParam = "?device_id=" + this.pos.id + "&user_id=" + JSON.parse(localStorage.getItem("user")).user.id;
                this.getCashierStatistics(this.selectedParam)
            } else {
                this.selectedParam = "?device_id=" + this.pos.id;
                this.getCashierStatistics(this.selectedParam)
            }
        },
        // getDrawerInfo() {
        //     this.loadingDrawer = true;
        //     cashDrawerService.open().then((res) => {
        //         if (res.data.status && res.data.status == "open") {
        //             this.drawerInfo = res.data;
        //             this.drawerInfo.total_cash_payment = (this.drawerInfo.total_cash - this.drawerInfo.opening_amount).toFixed(3);
        //             this.drawerOpened = true;
        //         }
        //     }).catch((error) => {
        //         console.log("error api : ", error);
        //     }).finally(() => {
        //         this.loadingDrawer = false;
        //     });
        // },
    }
});
</script>

<style scoped>
.footer {
    display: flex;
    align-items: center;
}

.table-status {
    display: flex;
    padding-right: 10px;
}

.place-order {
    display: flex;
    justify-content: space-evenly;
}

.occupied {
    background-color: #FF0033;
}

.onHold {
    background-color: #1891EF;
}

.vacant {
    background-color: #09AA29;
}

.reserved {
    background-color: #FE7A36;
}

.table-title {
    font-weight: 700;
    font-size: 16px;
}

.text-grey {
    color: #718096;
    font-weight: 400;
    font-size: 15px;
}

.order-btn {
    transition: transform 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.order-btn:hover {
    transform: translateY(-5px);
}
</style>