<template>
    <div class="flex mb-4 items-center justify-between">
        <span class="text-xl font-bold">
            {{ $t('Pos.SaleHistory') }}
        </span>
        <div class="flex items-end">
            <a-button @click="chooseCustomer" class="text-primary bg-white py-4 flex items-center border-primary font-semibold border-2">
                <FilterOutlined />
                {{
                $t("Pos.Filters")
            }}</a-button>
        </div>
    </div>
    <div class="bg-white h-auto flex flex-col rounded-md px-4 py-3">
        <div class="flex-grow mt-6">
            <a-table class="ant-table-striped" size="middle" :columns="columns" :data-source="sales" :pagination="false"
                @focus="test" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">

                <template #bodyCell="{ column, text, record }">
                    <div @click="selectSale(record)">
                        <template v-if="column.dataIndex === 'id'">
                            <a>{{ '#' + text }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'created_at'">
                            <a>{{ formatDate(text) }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'total'">
                            <a>{{ formatAmount(text, currencyCode, decimalPrecision) }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'notes'">
                            <a>{{ record?.payments[0]?.notes }}</a>
                        </template>
                    </div>
                </template>
            </a-table>
            <div class="flex justify-end mt-4">
                <a-pagination v-model:current="currentPage" :total="totalItems" @change="handlePageChange"
                    :default-page-size="perPage" :showSizeChanger=false />
            </div>

        </div>

    </div>
</template>

<script>
import { currency } from '@/_helpers';
import { FilterOutlined } from '@ant-design/icons-vue';
import moment from "moment/moment";

export default {
    name: "LbrxListSales",
    components: {
        FilterOutlined
    },
    data() {
        return {
            columns: [
                {
                    title: this.$t('Pos.OrderId'),
                    dataIndex: 'id',
                },
                {
                    title: this.$t('Pos.Date'),
                    dataIndex: 'created_at',
                },
                {
                    title: this.$t('Pos.TotalSales'),
                    dataIndex: 'total',
                },
                {
                    title: this.$t('Pos.DrawerNote'),
                    dataIndex: 'notes',
                },
            ],
            currentPage: 1,
        };
    },
    props: {
        sales: {
            required: true,
            value: Array
        },
        totalItems: {
            required: true,
            value: Number
        },
        perPage: {
            required: true,
            value: Number
        },
        currencyCode: {
            required: false,
            value: String
        },
        decimalPrecision: {
            required: false,
            value: Number,
            default: 3
        }
    },
    methods: {
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        selectOrder(item) {
            this.$emit("selectOrder", item)
        },
        formatDate(date) {
            return moment(date).locale(this.$i18n.locale == 'ar' ? 'ar-tn' : this.$i18n.locale).format('LLLL')
        },
        selectSale(item) {
            this.$emit("selectSale", item)
        },
        searchSales(event) {
            const inputValue = event.target.value;
            this.$emit("searchSales", inputValue);
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.$emit("handlePageChange", page);
        }
    }
};
</script>

<style>

</style>