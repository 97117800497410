<template>
  <div class="bg-[#fff] flex rounded-lg my-[17px] px-2 py-[13px] gap-2 h-fit border-2 border-b-gray-300 overflow-hidden"
    :class="fitWidth ? 'w-fit' : ''">
    <a-range-picker v-if="type == 'range'" style="width: 100%;" :placeholder="placeholder" v-model:value="range"
      @change="selectFilterInterval" :show-time="{
        hideDisabledOptions: true,
        format: 'HH:mm',
        defaultValue: defaultValue
      }" format="YYYY-MM-DD HH:mm"  :disabledDate ="disabledDate" />
    <a-date-picker v-else style="width: 100%;" :placeholder="$t('Pos.ReportDate')" v-model:value="date"
      :format="dateFormat" @change="selectFilterdate" />
  </div>
</template>

<script>
import dayjs , { Dayjs }from 'dayjs';
export default {
  name: "LbrxFilterBarDate",
  components: {
  },
  data() {
    return {
      dateFormat: 'YYYY/MM/DD',
      dateFormat2: 'YYYY-MM-DD HH:mm:ss',
      range: [],
      date: null,
      placeholder: [this.$t('Pos.StartDate'), this.$t('Pos.EndDate')],
      defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')]
    };
  },
  props: {
    fitWidth: {
      required: false,
      value: Boolean,
      default: false
    },
    type: {
      required: false,
      value: String,
      default: "range"
    }
  },
  methods: {
    selectFilterInterval() {
      this.$emit("selectFilterInterval", this.range);
    },
    selectFilterdate(date, dateString) {
      this.$emit("selectFilterdate", dateString);
    },
    disabledDate(current) {
  // Can not select days before today and today
  return current && current >= dayjs().endOf('day');
}
  }
};
</script>

<style>
.ant-btn-primary {
  background-color: darkgrey;
}
</style>