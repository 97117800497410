<template>
  <div class="overflow-hidden h-full flex flex-col border-l-2 border-b-gray-300">
    <div class="bg-[#fff] flex justify-between py-3 px-2 font-semibold">
      <div class="flex flex-col text-left">
        <span class="text-lg font-bold">{{ $t('Pos.OrderId') }} #{{ selectedSale.id }}</span>
        <span v-if="selectedSale.customer">{{ selectedSale.customer?.first_name + " " + selectedSale.customer?.last_name
          }}</span>
        <span v-else>{{ $t('Pos.Anonymous') }}</span>
      </div>
      <div class="flex items-end font-light">
        <span v-if="selectedSale.table_id">{{ $t('Pos.Table') }} | {{ selectedSale.table_id }}</span>
        <span v-else>{{ $t('Pos.TakeAway') }}</span>
      </div>
    </div>
    <div class="bg-gray-200 h-[1px]"></div>
    <div class="bg-[#fff] flex flex-col flex-1 space-y-1 px-2 py-4 overflow-y-auto overflow-x-hidden">
      <a-list :loading="initLoading" item-layout="horizontal" :data-source="selectedSale.sale_details" :split="false">
        <template #renderItem="{ item, index }">
          <a-list-item class="text-left rounded-md font-bold"
            :style="index % 2 == 0 ? 'background-color: #f5f5f5;' : ''">
            <a-skeleton avatar :title="false" :loading="!!item.loading" active>
              <a-list-item-meta :description="item.product.description">
                <template #title>
                  <span>{{ item.product.name }}</span>
                </template>
                <template #avatar>
                  <span>{{ item.quantity }}</span>
                </template>
              </a-list-item-meta>
              <div v-if="selectedSale.is_offer == 0" class="font-bold"> {{
                parseFloat(item.total).toFixed(decimalPrecision) }}
              </div>
              <div v-else class="font-bold">{{ $t('Pos.Offer') }}</div>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </div>
    <div class="bg-[#fff]">

    </div>
    <div class="flex flex-col mx-3 my-2">
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Subtotal') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedSale.sub_total ?
          selectedSale.sub_total?.toFixed(decimalPrecision) : (0).toFixed(decimalPrecision)
          }}</span></div>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Tax') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedSale.total_vat ?
          selectedSale.total_vat?.toFixed(decimalPrecision) : (0).toFixed(decimalPrecision) }}</span></div>
      </div>
      <div v-if="selectedSale.discount_value" class="flex justify-between text-sm">
        <span>{{ $t('Pos.Discount') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>>{{ currencyCode }}</span> <span>-{{
          selectedSale.discount_value
          }}</span></div>
      </div>
      <div class="flex justify-between text-xl font-semibold my-2">
        <span>{{ $t('Pos.GrandTotal') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span><span>{{
          selectedSale.total ? parseFloat(selectedSale.total).toFixed(decimalPrecision) : (0).toFixed(decimalPrecision)
        }}</span></div>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Cash') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedSale.payments &&
          selectedSale.payments.length != 0 ? Math.ceil((parseFloat(selectedSale?.payments[0]?.change_due) +
            parseFloat(selectedSale?.payments[0]?.amount_paid))).toFixed(decimalPrecision) : (0).toFixed(decimalPrecision)
          }}</span></div>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Balance') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedSale.payments &&
          selectedSale.payments.length != 0 ?
          parseFloat(selectedSale?.payments[0]?.change_due).toFixed(decimalPrecision) :
          (0).toFixed(decimalPrecision) }}</span></div>
      </div>
    </div>
    <div class="flex mx-2 my-3 gap-2">
      <button v-if="selectedFilter.type == 'offline'"
        class="bg-[#ee7918] flex w-full rounded-md py-3 text-lg items-center justify-center gap-3 text-white">
        <iconRefresh :color="'#fff'" :size="20" />{{ $t('Pos.SyncOrder') }}
      </button>
      <button @click="deleteOrder"
        class="bg-[#e65353] flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"><span
          class="scale-[200%] pb-1">&times;</span>{{ $t('Pos.Cancel') }}</button>
      <button @click="confirmOrder"
        class="flex w-full rounded-md py-3 text-lg items-center justify-center gap-3 text-white"
        :class="!loadingPrinting ? 'bg-[#09aa29]' : 'bg-[#bebebe]'">
        <div v-if="!loadingPrinting" class="flex gap-3 items-center">
          <iconPrint :color="'#fff'" :size="20" />{{ $t('Pos.Print') }}
        </div>
        <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
      </button>
    </div>
  </div>
</template>

<script>
import { notificationPlay } from '../_helpers';
import iconRefresh from './icons/iconRefresh.vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import iconPrint from './icons/iconPrint.vue';
export default {
  name: "LbrxOrdersSideBar",
  components: {
    iconRefresh,
    iconPrint,
    LoadingOutlined
  },
  data() {
    return {
      amountToGive: 0,
    };
  },
  computed: {
  },
  props: {
    selectedSale: {
      required: true,
      value: Object
    },
    selectedFilter: {
      required: false,
      value: Object
    },
    loadingPrinting: {
      required: false,
      value: Boolean
    },
    currencyCode: {
      required: false,
      value: String
    },
    currencyDirection: {
      required: false,
      value: String
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    }
  },
  methods: {
    removeQuantity(product) {
      this.$emit('removeQuantity', product);
    },
    addQuantity(product) {
      this.$emit('addQuantity', product);
    },
    addAmount(amount) {
      this.amountToGive += amount;
      notificationPlay.beep();
    },
    deleteAmount() {
      this.amountToGive = 0;
      notificationPlay.delete();
    },
    confirmOrder() {
      this.$emit("confirmOrder");
    },
    deleteOrder() {
      this.$emit("deleteOrder");
    },
  }
};
</script>

<style scoped></style>