<template>
  <iconHome v-if="icon=='Home'" :size="iconSize" :color="iconColor" />
  <iconUser v-if="icon=='Customers'" :size="iconSize" :color="iconColor" />
  <iconTable v-if="icon=='Tables'" :size="iconSize" :color="iconColor" />
  <iconCash v-if="icon=='Cashier'" :size="iconSize" :color="iconColor" />
  <iconOrders v-if="icon=='Orders'" :size="iconSize" :color="iconColor" />
  <iconReports v-if="icon=='Reports'" :size="iconSize" :color="iconColor" />
  <iconSetting v-if="icon=='Settings'" :size="iconSize" :color="iconColor" />
  <iconRefund v-if="icon=='Refund'" :size="iconSize" :color="iconColor" />
  <iconStock v-if="icon=='Stock'" :size="iconSize" :color="iconColor" />
</template>

<script>
import iconHome from "./icons/iconHome.vue"
import iconUser from "./icons/iconUser.vue"
import iconTable from "./icons/iconTable.vue"
import iconCash from "./icons/iconCash.vue"
import iconOrders from "./icons/iconOrders.vue"
import iconReports from "./icons/iconReports.vue"
import iconSetting from "./icons/iconSetting.vue"
import iconRefund from "./icons/iconRefund.vue"
import iconStock from "./icons/iconStock.vue"
export default {
name: 'LbrxSideMenuIcons',
components: {
  iconHome,
  iconUser,
  iconTable,
  iconCash,
  iconOrders,
  iconReports,
  iconSetting,
  iconRefund,
  iconStock
},
props: {
  icon: String,
  iconSize: Number,
  iconColor: String
}
}
</script>