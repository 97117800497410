<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-44" @click="closePopupLang">
    <div
      class="bg-[#fff] w-[300px] py-14 rounded-3xl overflow-hidden flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopupLang">&times;</span>
      <div class="flex flex-col items-start gap-5 mx-6">
        <div @click="setLanguage('en')" class="py-3 px-3 flex gap-x-3 border-2 w-full" :class="selectedLanguage == 'en' ? 'border-primary' : 'border-gray-400'"><img src="../assets/icons/english.svg" class="w-6" alt="">English</div>
        <div @click="setLanguage('fr')" class="py-3 px-3 flex gap-x-3 border-2 w-full" :class="selectedLanguage == 'fr' ? 'border-primary' : 'border-gray-400'"><img src="../assets/icons/french.svg" class="w-6" alt="">français</div>
        <div @click="setLanguage('ar')" class="py-3 px-3 flex gap-x-3 border-2 w-full" :class="selectedLanguage == 'ar' ? 'border-primary' : 'border-gray-400'"><img src="../assets/icons/arabic.svg" class="w-6" alt="">العربية</div>
      </div>
    </div>
  </div>
</template>

<script>
// import LbrxKeyboard from "@/components/LbrxKeyboard.vue"
export default {
  name: "LbrxLanguagePopup",
  components: {
    // LbrxKeyboard
  },
  data() {
    return {
      activeKey: '0'
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    selectedLanguage: {
      required: true,
      value: Object
    }
  },
  methods: {
    closePopupLang() {
      this.$emit("closePopupLang")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    setLanguage(lang) {
      this.$emit("setLanguage", lang)
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>