<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[370px] my-52 rounded-3xl overflow-hidden h-fit flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow items-center h-full mx-10 my-9 gap-6">
        <iconAlert :size="90" :color="getSelectedTypeStyleIcon" class="w-56" alt="" />
        <div class="flex flex-col w-full gap-6">
          <span>{{ message }}</span>
          <button @click="confirm"
        class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :style="`background-color: ${primaryColor};`" >{{ $t('Pos.Confirm') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconAlert from "./icons/iconAlert.vue"
export default {
  name: "LbrxRebootPopup",
  components: {
    iconAlert
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      quantity: ""
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    primaryColor: {
      required: true,
      value: String
    },
    message: {
      required: true,
      value: String
    }
  },
  computed: {
    getSelectedTypeStyleIcon() {
      return JSON.parse(localStorage.getItem('themeTemplate')).primaryColor;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirm() {
      this.$emit("confirm")
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>