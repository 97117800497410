<template>
  <svg :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
    stroke="currentColor">
    <path d="M20 12L4 12" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>

export default {
  name: 'iconMinus',
  props: {
    size: Number,
    color: String
  }
}
</script>