<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 22H4.4A2.4 2.4 0 0 1 2 19.6V18m16 4h1.6a2.4 2.4 0 0 0 2.4-2.4V18m0-12V4.4A2.4 2.4 0 0 0 19.6 2H18M6 2H4.4A2.4 2.4 0 0 0 2 4.4V6"
      :stroke="color" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18 9v6M14 9v6M10 9v6M6 9v6" :stroke="color" stroke-width="1.5" stroke-miterlimit="10"
      stroke-linecap="round" />
  </svg>
</template>

<script>

export default {
  name: 'iconBarcode',
  props: {
    size: Number,
    color: String
  }
}
</script>