<template>
  <div class="overflow-hidden h-full flex flex-col border-l-2 border-b-gray-300">
    <div class="bg-[#fff] flex justify-between py-3 px-2 font-semibold">
      <div class="flex flex-col text-left">
        <span class="text-lg font-bold">{{ $t('Pos.CartId') }} #{{ selectedCart.id }}</span>
        <span v-if="selectedCart.customer">{{ selectedCart.customer?.first_name + " " + selectedCart.customer?.last_name
          }}</span>
        <span v-else>{{ $t('Pos.Anonymous') }}</span>
      </div>
      <div class="flex items-end font-light">
        <span v-if="selectedCart.table_id">{{ $t('Pos.Table') }} | {{ selectedCart.table_id }}</span>
        <span v-else>{{ $t('Pos.TakeAway') }}</span>
      </div>
    </div>
    <div class="bg-gray-200 h-[1px]"></div>
    <div class="bg-[#fff] flex flex-col flex-1 space-y-1 px-2 py-4 overflow-y-auto overflow-x-hidden">
      <a-list :loading="initLoading" item-layout="horizontal" :data-source="selectedCart.cart_items" :split="false">
        <template #renderItem="{ item, index }">
          <a-list-item class="text-left rounded-md font-bold"
            :style="index % 2 == 0 ? 'background-color: #f5f5f5;' : ''">
            <a-skeleton avatar :title="false" :loading="!!item.loading" active>
              <a-list-item-meta :description="item.product.description">
                <template #title>
                  <span>{{ item.product.name }}</span>
                </template>
                <template #avatar>
                  <span>{{ item.quantity }}</span>
                </template>
              </a-list-item-meta>
              <div class="font-bold">{{ item.total }}</div>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </div>
    <div class="bg-[#fff]">

    </div>
    <div class="flex flex-col mx-3 my-2">
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Subtotal') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedCart.sub_total ? parseFloat(selectedCart.sub_total).toFixed(decimalPrecision) : (0).toFixed(decimalPrecision)
          }}</span></div>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Tax') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{currencyCode}}</span> <span>{{ selectedCart.total_vat ? parseFloat(selectedCart.total_vat).toFixed(decimalPrecision) : (0).toFixed(decimalPrecision) }}</span></div>
      </div>
      <div v-if="selectedCart.discount_value && selectedCart.discount_value != 0" class="flex justify-between text-sm">
        <span>{{ $t('Pos.Discount') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>-{{ parseFloat(selectedCart.discount_value).toFixed(decimalPrecision) }}</span></div>
      </div>
      <div class="flex justify-between text-xl font-semibold my-2">
        <span>{{ $t('Pos.GrandTotal') }}</span><div class="font-semibold flex gap-1" :style="currencyDirection=='right' ? 'direction: rtl' : ''"><span>{{ currencyCode }}</span> <span>{{ selectedCart.total ? parseFloat(selectedCart.total).toFixed(decimalPrecision) : (0).toFixed(decimalPrecision) }}</span></div>
      </div>
    </div>
    <div class="flex mx-2 my-3 gap-2">
      <button v-if="selectedFilter.type == 'offline'"
        class="bg-primary flex w-full rounded-md py-3 text-lg items-center justify-center gap-3 text-white">
        <iconRefresh :color="'#fff'" :size="20" />{{ $t('Pos.SyncOrder') }}
      </button>
      <button @click="confirmCart"
        class="flex w-full rounded-md py-3 text-lg items-center justify-center gap-3 text-white"
        :class="!loading ? 'bg-primary' : 'bg-[#bebebe]'">
        <div v-if="!loading" class="flex gap-3 items-center">
          <iconPrint :color="'#fff'" :size="20" />{{$t('Pos.ResumeCart')}}
        </div>
        <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
      </button>
    </div>
  </div>
</template>

<script>
import { notificationPlay } from '../_helpers';
import iconRefresh from './icons/iconRefresh.vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import iconPrint from './icons/iconPrint.vue';
export default {
  name: "LbrxCartSideBar",
  components: {
    iconRefresh,
    iconPrint,
    LoadingOutlined
  },
  data() {
    return {
      amountToGive: 0,
    };
  },
  computed: {
  },
  props: {
    selectedCart: {
      required: true,
      value: Object
    },
    selectedFilter: {
      required: false,
      value: Object
    },
    loading: {
      required: false,
      value: Boolean
    },
    currencyCode: {
      required: false,
      value: String
    },
    currencyDirection: {
      required: false,
      value: String
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    }
  },
  methods: {
    confirmCart() {
      if(this.selectedCart.id) {
        this.$emit("confirmCart");
      }
    },
  }
};
</script>

<style scoped></style>