<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 5V19H20V3" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 19L14 13.5372C14 11.3938 10 11.5836 10 13.5372L10 19" :stroke="color" stroke-width="1.5"
      stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 22L10 22" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 5L10.7351 2.26014C11.9889 1.91329 12.0111 1.91329 13.2649 2.26014L21 5" :stroke="color"
      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.0119 8H12" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

</template>

<script>

export default {
  name: 'iconStock',
  props: {
    size: Number,
    color: String,
    colorFill: String
  }
}
</script>