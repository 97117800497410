import beepSound from '../assets/sounds/sound_beep.mp3';
import deleteSound from '../assets/sounds/sound_delete.mp3';

function playSound(sound: string) {
    const audio = new Audio(sound);
    audio.play();
}

export const notificationPlay = {
    beep: () => playSound(beepSound),
    delete: () => playSound(deleteSound),
};