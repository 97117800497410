<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-44" @click="closePopup">
    <div
      class="bg-[#fff] w-[700px] py-4 rounded-3xl overflow-hidden flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <div class="flex flex-col items-start gap-5 mx-6">
        <span class="text-2xl font-semibold">{{ $t('Pos.EnterSale') }}</span>
        <input @keyup.enter="confirmSale" v-model="saleCode" type="text"
          class="w-full text-3xl rounded-sm border-2 border-gray-300 px-2 font-medium">
      </div>
      <div class="flex flex-col flex-grow h-full mt-3">
      </div>
      <div class="flex flex-row justify-end gap-4 mx-6 mt-5">
        <button @click="closePopup" class="border-2 border-primary flex w-48 rounded-md py-2 text-lg items-center justify-center gap-3 text-primary">{{ $t('Pos.Cancel') }}</button>
        <button @click="confirmSale" class="bg-primary flex w-48 rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{ $t('Pos.Confirm') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
// import LbrxKeyboard from "@/components/LbrxKeyboard.vue"
export default {
  name: "LbrxSalePopup",
  components: {
    // LbrxKeyboard
  },
  data() {
    return {
      activeKey: '0',
      saleCode: ""
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmSale() {
      this.$emit("confirmSale", this.saleCode)
      this.saleCode = "";
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>