<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 17.5L22 22" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" :stroke="color" stroke-width="1.5" stroke-linejoin="round"/>
</svg>


</template>

<script>

export default {
  name: 'iconSearchV2',
  props: {
    size: Number,
    color: String
  }
}
</script>