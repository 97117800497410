import {authHeader, ApiConfigs} from "../_helpers";
// import store from '@/store';

export const authService = {
    login,
    loginByBadge,
    loginByPin,
    logout,
    generateCaptcha,
    generateCode,
    authCodeConfirm,
    handleResponseLougout
};

function generateCaptcha() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.accounts.logout}`, requestOptions).then(
        handleResponseLougout
    );
}

function login(email: string, password: string) {
    const payload: Record<string, any> = {};

    payload.email = email;
    payload.password = password;
    // payload.captcha = captcha;
    // payload.key = key;
    
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login}`,
        requestOptions
    ).then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                //   localStorage.setItem("token", user.token);
            }
            return user;
        });
}

function loginByBadge(payload: object) {
    
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.loginBagde}`,
        requestOptions
    ).then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                //   localStorage.setItem("token", user.token);
            }
            return user;
        });
}

function loginByPin(payload: object) {
    
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.loginPin}`,
        requestOptions
    ).then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                //   localStorage.setItem("token", user.token);
            }
            return user;
        });
}

function generateCode(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };
    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.device.generate}`,
        requestOptions
    ).then(handleResponse);
}

function authCodeConfirm(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload)
    };
    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.device.authCodeConfirm}`,
        requestOptions
    ).then(handleResponse);
}

function logout() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.accounts.logout}`, requestOptions).then(
        handleResponseLougout
    );
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseLougout(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            location.reload();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}
