import { createStore } from 'vuex'

export default createStore({
  state: {
    showMenuSidebar: true,
    cart: {},
  },
  getters: {
    getShowMenuSidebar(state) {
      return state.showMenuSidebar;
    },
    getCart(state) {
      return state.cart;
    },
  },
  mutations: {
    set_showMenuSidebar(state, showMenuSidebar) {
      state.showMenuSidebar = showMenuSidebar;
    },
    set_cart(state, cart) {
      state.cart = cart;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    store => {
      // Save state to localStorage on every mutation
      store.subscribe((mutation, state) => {
        localStorage.setItem('myState', JSON.stringify(state));
      });
    }
  ]
})
