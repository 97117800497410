<template>
  <div class="flex justify-between py-3 px-3 border-b-2 border-b-gray-300">
    <div class="flex gap-5">
      <!-- <iconSearch :size="24" :color="'#fff'"/> -->
      <img src="/posys-logo-small.png" class="h-11" alt="">
      <LbrxSearchBar :posConfig="posConfig" :isPortrait="isPortrait" />
    </div>
    <div class="flex gap-3">
      <button ref="languageRef" @click="showLangPopup" class="px-3 rounded-md bg-[#f4f4f4]">
          <iconGlobe :size="8" :color="primaryColor" class="w-5 my-2" />
        </button>
      <button @click="refreshPage" class="px-3 rounded-md bg-[#f4f4f4]"><img src="../assets/icons/refresh.svg"
          class="w-5" alt=""></button>
      <button class="px-3 rounded-md bg-[#f4f4f4]">
        <img v-if="onLine" src="../assets/icons/wifi.svg" class="w-5" alt="">
        <iconNoWifi v-else :size="20" :color="'#000'" class="w-5" alt="" />
      </button>
      <button class="flex px-3 rounded-md items-center justify-center text-sm gap-2 text-white"
        :class="takeAway ? 'bg-primary' : 'bg-gray-400'" @click="toggleTakeAway()">
        <iconCar :size="20" :color="'#fff'" class="w-5" alt="" /><span v-if="!isPortrait">{{ $t('Pos.TakeAway') }}</span>
      </button>
      <button v-if="this.posConfig.posConfig.show_table" class="flex px-3 rounded-md items-center justify-center text-white text-sm gap-2"
        :class="!takeAway ? 'bg-primary' : 'bg-gray-400'" @click="goToTables"><img src="../assets/icons/menu.svg"
          class="w-5" alt=""><span v-if="!isPortrait">{{ table.id ?
        $t('Pos.TableNumber', { value: table.number }) : $t('Pos.SelectTable') }}</span></button>
    </div>
  </div>
  <LbrxLanguagePopup :active="modalLanguage.active" :selectedLanguage="selectedLanguage"
    @closePopupLang="closePopupLang" @setLanguage="setLanguage" />
</template>

<script>
import LbrxLanguagePopup from "./LbrxLanguagePopup.vue"
import iconNoWifi from "./icons/iconNoWifi.vue"
import iconCar from "./icons/iconCar.vue"
import LbrxSearchBar from "./LbrxSearchBar.vue"
import router from "@/router";
import { cartService } from "@/_services";
import iconGlobe from "./icons/iconGlobe.vue"
import { notification } from 'ant-design-vue';
export default {
  name: "LbrxHeader",
  components: {
    LbrxSearchBar,
    iconNoWifi,
    iconCar,
    iconGlobe,
    LbrxLanguagePopup
  },
  props: {
    themeTemplate: {
      required: true,
      value: Object
    },
    posConfig: {
      required: true,
      value: Object
    },
    onLine: {
      required: true,
      value: Boolean
    },
    isPortrait: {
      required: true,
      value: Boolean
    }
  },
  data() {
    return {
      table: {},
      takeAway: false,
      modalLanguage: {
        active: false
      },
      selectedLanguage: "",
    };
  },
  created() {
    if (localStorage.getItem('table')) {
      this.table = JSON.parse(localStorage.getItem('table'));
    }
    if (localStorage.getItem('cart')) {
      this.takeAway = JSON.parse(localStorage.getItem('cart')).is_takeaway;
    }

    window.addEventListener('table-localstorage-changed', (event) => {
      this.table = event.detail.storage;
      this.takeAway = false;
    });
    this.selectedLanguage = localStorage.getItem("user_language");
  },
  methods: {
    showLangPopup() {
      this.modalLanguage.active = true;
    },
    closePopupLang() {
      this.modalLanguage.active = false;
    },
    goToTables() {
      // this.toggleTakeAway(false);
      router.push("/tables");
    },
    refreshPage() {
      location.reload();
    },
    toggleTakeAway() {
      if(JSON.parse(localStorage.getItem("cart"))) {

          let payload = {
            user_id: JSON.parse(localStorage.getItem("user")).user.id,
            is_takeaway: this.takeAway ? 0 : 1,
            table_id: null
          }
          cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
            this.showNotification(this.$t('Pos.CartUpdated'));
            this.takeAway = res.data.is_takeaway;
            localStorage.setItem("cart", JSON.stringify(res.data));
            if(this.takeAway) {
              localStorage.removeItem("table");
              this.table = {};
            } else {
              this.takeAway = false;
            }
          }).catch((error) => {
            this.showErrorNotification(this.$t('Pos.ErrorTry'));
            console.log("error api : ", error);
          }).finally(() => {
            // 
          });
      }
    },
    setLanguage(lang) {
      localStorage.setItem('user_language', lang)
      this.$i18n.locale = localStorage.getItem('user_language')
      this.closePopupLang();
      location.reload();
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'

        }
      });
    },
  }


};
</script>

<style scoped></style>