<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[670px] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow h-full mx-10 mt-6">
        <span class="text-left text-lg font-bold mx-8">{{ title }}</span>
        <span class="text-left mx-8"><span class="font-semibold">{{ $t('Pos.PinRole') }}</span></span>
        <LbrxNumPad :amountToGive="pinCode" @addAmount="addAmount" :pincodeMode="true" :cashierMode="true" @confirmAction="confirmCode" />
      </div>
    </div>
  </div>
</template>

<script>
import LbrxNumPad from "@/components/LbrxNumPad.vue"
export default {
  name: "LbrxPincodePopup",
  components: {
    LbrxNumPad
  },
  data() {
    return {
      activeKey: '0',
      pinCode: ""
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    selectedItem: {
      required: true,
      value: Object
    },
    title: {
      required: false,
      value: String,
      default: ""
    }
  },
  computed: {
    productPriceFormat() {
      return this.selectedItem.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmCode() {
      this.$emit("confirmCode", this.pinCode)
      this.pinCode = "";
    },
    addAmount(item) {
      if (item === -1) {
        this.pinCode = "";
      } else if (item === -2) {
        this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1);
      } else {
        this.pinCode += item;
      }
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>