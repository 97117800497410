<template>
    <div class="relative flex flex-col h-full w-full px-4 overflow-y-auto">
        <LbrxLoaderBlur v-if="showLoader" />
        <div class="mb-4">
            <div class="flex gap-3">
                <!-- <span>{{ $t('Pos.Display') }} :</span>
                    <a-select ref="select" v-model:value="displayTable" style="width: 250px" @focus="focus"
                        @change="handleChange">
                        <a-select-option value="AllStock">{{ $t('Pos.Stock') }}</a-select-option>
                        <a-select-option value="Endofstock">{{ $t('Pos.Endofstockproducts') }}</a-select-option>
                    </a-select> -->
                <LbrxFilterBar :filters="filtersStock" :selectedFilter="displayTable" @selectFilter="handleChange"
                    :fitWidth="true" class="h-fit" :title="$t('Pos.Display')" />


                <LbrxFilterBar :filters="filtersData" :selectedFilter="selectedFilterData"
                    @selectFilter="selectFilterData" :fitWidth="true" class="h-fit" :title="$t('Pos.FilterBy')" />

            </div>

            <a-row class="charts-container" :gutter="24">

                <a-col v-if="displayTable.id == 2" :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }"
                    :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                    <a-card hoverable>

                        <a-row class="tbl-title" :gutter="16">

                            <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 16 }"
                                :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">

                                <ExclamationCircleOutlined
                                    :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                                <span class="text-xl font-semibold text-red-700">{{ $t('Pos.Endofstockproducts')
                                    }}</span>

                            </a-col>


                            <!-- <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }">
                        <a-select v-model:value="warehouse_id" show-search :option-filter-prop="'label'" class="select-input" @change="handleWarehouseChange">
                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

<a-select-option v-for="item in state.warehouses" :label="item.name" :key="item.id" :value="item.id"
    :selected="item.id === warehouse_id ">{{ item.name }}</a-select-option>
</a-select>
</a-col> -->

                        </a-row>

                        <a-table :columns="columnsEndOfStock" :data-source="endOfStock" class="table-container">
                            <template #emptyText>
                                <a-empty :description="$t('Pos.NoData')" :image="simpleImage" />
                            </template>
                            <template #bodyCell="{ column, record, text }">
                                <div :class="record.total == 0 ? 'warning-txt' : ''">
                                    <template v-if="column.dataIndex === 'thumbnail'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'name'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'total'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.key === 'operation'">

                                        <button @click="openSendRequest(record)"
                                            class="bg-primary py-2 px-4 text-white text-nowrap rounded-md">{{
                                                $t('Pos.SendRequest') }}</button>
                                    </template>
                                </div>
                            </template>
                            <!-- <template
                                #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                                <div style="color: aqua;">
                                    <a-input ref="searchInput" :placeholder="'title'" :value="selectedKeys[0]"
                                        style="width: 188px; margin-bottom: 8px; display: block"
                                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

                                    <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                                        <template #icon>
                                            <SearchOutlined />
                                        </template>
                                        {{ $t('Pos.Search') }}
                                    </a-button>
                                    <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                                        {{ $t('Pos.Reset') }}
                                    </a-button>
                                </div>
                            </template> -->

                            <template #customFilterIcon="{ filtered }">
                                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                            </template>
                        </a-table>
                    </a-card>
                </a-col>

                <a-col v-else :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 24 }"
                    :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                    <a-card hoverable>

                        <a-row class="tbl-title" :gutter="16">

                            <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 16 }"
                                :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">

                                <ExclamationCircleOutlined
                                    :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                                <span class="text-xl font-semibold">{{ $t('Pos.Stock')
                                    }}</span>

                            </a-col>


                            <!-- <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }">
            <a-select v-model:value="warehouse_id" show-search :option-filter-prop="'label'" class="select-input" @change="handleWarehouseChange">
                <template #notFoundContent>
                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>

<a-select-option v-for="item in state.warehouses" :label="item.name" :key="item.id" :value="item.id"
:selected="item.id === warehouse_id ">{{ item.name }}</a-select-option>
</a-select>
</a-col> -->

                        </a-row>

                        <a-table :columns="columnsStock" :data-source="allStock" class="table-container">
                            <template #emptyText>
                                <a-empty :description="$t('Pos.NoData')" :image="simpleImage" />
                            </template>
                            <template #bodyCell="{ column, record, text }">
                                <div>
                                    <template v-if="column.dataIndex === 'name'">

                                        <span>{{ record.stockable.code_ean }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'stockable'">

                                        <span>{{ record.stockable.name }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'quantity'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.key === 'operation'">

                                        <button @click="showQuantityPopup(record)"
                                            class="bg-primary py-2 px-2 text-white text-nowrap rounded-md"><iconPlus :size="20" :color="'#fff'" /></button>
                                    </template>
                                </div>
                            </template>
                            <!-- <template
                    #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="color: aqua;">
                        <a-input ref="searchInput" :placeholder="'title'" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            {{ $t('Pos.Search') }}
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            {{ $t('Pos.Reset') }}
                        </a-button>
                    </div>
                </template> -->

                            <template #customFilterIcon="{ filtered }">
                                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                            </template>
                        </a-table>
                    </a-card>
                </a-col>

            </a-row>
        </div>
        <LbrxInfoPopup :active="modalInfo.active" @closePopup="closePopup" :type="modalInfo.type"
            :message="modalInfo.message" />
        <LbrxRequestPopup :active="modalRequest.active" @closePopup="closePopup" :productName="selectedProduct.name" />
        <LbrxQuantityPopup :active="modalQuantity.active" @closePopup="closePopup" @confirmQty="confirmQty"
        :selectedItem="selectedItem.stockable" />
    </div>
</template>

<script>
import moment from "moment/moment";
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import LbrxInfoPopup from '@/components/LbrxInfoPopup.vue';
import LbrxRequestPopup from '@/components/LbrxRequestPopup.vue';
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { statisticService, stockItemsService } from '@/_services';
import { currency } from '@/_helpers';
import webSocketService from '@/_services/webSocketService';
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue";
import iconPlus from "@/components/icons/iconPlus.vue";
import LbrxQuantityPopup from "@/components/LbrxQuantityPopup.vue"

export default defineComponent({
    name: "stockIndex",
    components: {
        LbrxLoaderBlur,
        LbrxInfoPopup,
        LbrxRequestPopup,
        LbrxFilterBar,
        iconPlus,
        LbrxQuantityPopup
    },
    data() {
        return {
            data: {
                labels: ['January', 'February'],
                datasets: [
                    {
                        label: this.$t('Pos.ProfitMade'),
                        backgroundColor: '#f87979',
                        data: ["34", "78"]
                    }
                ],
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            },
            modalQuantity: {
                active: false
            },
            selectedFilter: {},
            filtersData: [{ name: this.$t('Pos.Cashier'), id: 1 }, { name: this.$t('Pos.User'), id: 2 }, { name: this.$t('Pos.All'), id: 3 }],
            filtersStock: [{ name: this.$t('Pos.Stock'), id: 1 }, { name: this.$t('Pos.Endofstock'), id: 2 }],
            selectedFilterPeriod: {},
            selectedFilterData: {},
            selectedFilterInterval: [],
            selectedFilterDate: moment().format('YYYY-MM-DD'),
            modalReserve: {
                active: false
            },
            modalInfo: {
                active: false,
                type: "",
                message: ""
            },
            modalRequest: {
                active: false
            },
            columnsEndOfStock: [
                {
                    title: this.$t('Pos.Photo'),
                    dataIndex: 'thumbnail'
                },
                {
                    title: this.$t('Pos.Product'),
                    dataIndex: 'name'
                },
                {
                    title: this.$t('Pos.Quantity'),
                    dataIndex: 'total',
                },
                {
                    title: this.$t('Pos.Action'),
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                },
            ],
            columnsStock: [
                {
                    title: this.$t('Pos.CodeEAN'),
                    dataIndex: 'name'
                },
                {
                    title: this.$t('Pos.Product'),
                    dataIndex: 'stockable',
                },
                {
                    title: this.$t('Pos.Quantity'),
                    dataIndex: 'quantity',
                },
                {
                    title: this.$t('Pos.Action'),
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                },
            ],
            tableLocations: [],
            statisticsSales: {},
            statisticsProducts: {},
            statisticsCashier: {},
            show: false,
            socket: null,
            showLoader: false,
            pos: {},
            posConfig: {},
            currency: {},
            currencyCode: {},
            drawerInfo: {},
            drawerOpened: false,
            loadingPrinting: false,
            NoOpenDrawer: false,
            isPortrait: window.matchMedia("(orientation: portrait)").matches,
            endOfStock: [],
            allStock: [],
            stockout: [],
            selectedProduct: {},
            displayTable: { name: this.$t('Pos.Stock'), id: 1 },
            selectedItem: {},
            selectedQty: null
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
        this.getAllProducts();
    },
    mounted() {
        this.socket = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        checkEfficiency() {
            return (this.statisticsSales.currentMonthEfficiency - this.statisticsSales.previousMonthEfficiency) < 0 ? false : true;
        },
        decimalPrecision() {
            return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
        }
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        openPopupInfo(message) {
            this.modalInfo.active = true;
            this.modalInfo.type = "message";
            this.modalInfo.message = message;
        },
        openPopupRequest() {
            this.modalRequest.active = true;
        },
        getAllProducts() {
            this.showLoader = true;
            statisticService.getAllProducts("?warehouse_id=" + this.posConfig.warehouse_id).then((res) => {
                this.statisticsProducts = res;
                this.endOfStock = res.products_out_of_stock;
                this.allStock = res.stock;
                this.stockout = this.endOfStock.filter((el) => el.total == 0);
                // if (this.stockout.length != 0) {
                //     this.openPopupInfo(this.$t('Pos.ThereIs', { num: this.stockout.length }) + " " + this.$t('Pos.Endofstockproducts'));
                // }
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        closePopup() {
            this.modalInfo.active = false;
            this.modalRequest.active = false;
            this.modalQuantity.active = false;
        },
        openSendRequest(product) {
            this.selectedProduct = product;
            this.openPopupRequest();
        },
        handleChange(value) {
            this.displayTable = value;
        },
        showQuantityPopup(item) {
            this.selectedItem = item;
            this.modalQuantity.active = true;
        },
        confirmQty(qty) {
            this.selectedQty = parseFloat(qty);
            this.updateStockQty();
            this.closePopup();
        },
        updateStockQty() {
            this.showLoader = true;
            let payload = {
                product_variant_id: this.selectedItem.product_variant_id ? this.selectedItem.product_variant_id : "",
                quantity: this.selectedQty,
                stockable_id: this.selectedItem.stockable_id,
                stockable_type: "product",
                unit: this.selectedItem.stockable.unit,
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                warehouse_id: this.selectedItem.warehouse_id
            }
            stockItemsService.create(payload).then((res) => {
                this.getAllProducts();
                this.showNotification(this.$t('Pos.StockUpdateSuccess'));
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoader = false;
            });
        }
    }
});
</script>

<style scoped>
.tbl-title {
    margin-bottom: 15px;
}

.warning-icon {
    color: #ea5455;
    font-size: 24px !important;
    padding-right: 10px;
}

.warning-icon-rtl {
    color: #ea5455;
    font-size: 24px !important;
    padding-left: 10px;
}

.warning-txt {
    color: #ea5455;
}

.tbl-right {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}
</style>