class WebSocketService {
    private socket: WebSocket | null = null;
    private token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'
  
    public connect(): void {
      if (this.socket) {
        return;
      }
  
      this.socket = new WebSocket("ws://localhost:8765");
  
      this.socket.onopen = (event) => {
        if(this.socket) {
            this.socket.send(this.token);
        }
      };

      this.socket.onmessage = (event) => {
        if (event.data == "Authentication successful") {
          // this.sendMessage();
        }
      };

      this.socket.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      this.socket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event}`);
      };
    }
  
    public disconnect(): void {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    }
  
    public getSocket(): WebSocket | null {
      return this.socket;
    }
  }
  
  export default new WebSocketService();