<template>
    <div class="flex flex-col flex-grow px-4 overflow-y-auto" :class="isPortrait ? 'h-[40vh] mb-4' : 'w-[58%]'">
        <input id="scanProduct" type="text" :style="{ position: 'absolute', left: '-9999px' }"
            @keyup.enter="confirmBarcode" v-model="barcode" @focus="isInputFocused = true"
            @blur="isInputFocused = false">
        <div class="flex my-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ showAddCustomer ? $t('Pos.AddCustomer') : $t('Pos.Customers') }}
            </span>
            <button v-if="!showAddCustomer" @click="goAddCustomer"
                class="text-white font-semibold bg-primary py-2 px-8 flex items-center border-none rounded-md transition ease-in-out delay-150 hover:-translate-y-1 duration-300">
                <iconPlus :size="20" :color="'#fff'" class="mx-2" />
                {{ $t("Pos.AddCustomer") }}
            </button>
        </div>
        <div class="mb-3">

            <LbrxCreateCustomer v-if="showAddCustomer" @createCustomer="createCustomer" @cancelCreate="cancelCreate" />
            <LbrxListCustomers v-if="!showAddCustomer" :customers="customers" :selectedCustomer="selectedCustomer"
                :showSalesCustomer="showSalesCustomer" :searchInputModel="searchInputModel"
                @selectCustomer="selectCustomer" @chooseCustomer="chooseCustomer" @searchCustomers="searchCustomers"
                @showSales="showSales" :posConfig="posConfig" :decimalPrecision="posConfig.posConfig.decimalPrecision"
                :currencyCode="currencyCode" />

        </div>
    </div>
    <div v-if="!isPortrait" class="w-[32%]">
        <LbrxCart :showLoaderCart="showLoaderCart" :cart="cart" :cartObject="cartObject"
            @removeQuantity="removeQuantity" @emptyCart="emptyCart" @deleteCartVar="deleteCartVar"
            @toggleCartLoader="toggleCartLoader" @gatCartItems="checkCartStatus" @showDiscount="showDiscount"
            @showCoupon="showCoupon" :currencyCode="currencyCode" @enableScanmode="enableScanmode"
            :isInputFocused="isInputFocused" :pos="pos" :posConfig="posConfig" />
    </div>
    <LbrxCustomizePopup :active="modal.active" :selectedItem="selectedItem" @closePopup="closePopup"
        @confirmItem="confirmItem" />
    <LbrxDiscountPopup :active="modalDiscount.active" @closePopup="closePopup" @confirmDiscount="confirmDiscount" />
    <LbrxCouponPopup :active="modalCoupon.active" @closePopup="closePopup" @confirmCoupon="confirmCoupon"
        :posConfig="posConfig" />
</template>

<script>
import LbrxCart from "@/components/LbrxCart.vue"
import iconPlus from "@/components/icons/iconPlus.vue"
import LbrxCreateCustomer from "@/components/LbrxCreateCustomer.vue"
import LbrxListCustomers from "@/components/LbrxListCustomers.vue"
import LbrxDiscountPopup from "@/components/LbrxDiscountPopup.vue"
import LbrxCouponPopup from "@/components/LbrxCouponPopup.vue"
import LbrxCustomizePopup from "@/components/LbrxCustomizePopup.vue"
import { notificationPlay } from '../../_helpers'
import { customerService, cartService, cartItemService, productService } from '@/_services';
import { notification } from 'ant-design-vue';
import router from "@/router";
import webSocketService from "@/_services/webSocketService"
import LbrxCartPortrait from "@/components/LbrxCartPortrait.vue"
export default {
    name: "customerIndex",
    components: {
        LbrxCart,
        iconPlus,
        LbrxCreateCustomer,
        LbrxListCustomers,
        LbrxDiscountPopup,
        LbrxCouponPopup,
        LbrxCustomizePopup,
        // LbrxCartPortrait
    },
    data() {
        return {
            modal: {
                active: false
            },
            modalQuantity: {
                active: false
            },
            modalDiscount: {
                active: false
            },
            modalCoupon: {
                active: false
            },
            showAddCustomer: false,
            showSalesCustomer: false,
            cartObject: {},
            showLoaderCart: false,
            cart: [],
            customers: [],
            selectedCustomer: {},
            searchInputModel: "",
            pos: {},
            posConfig: {},
            currency: {},
            currencyCode: "",
            selectedItem: {},
            selectedQty: "",
            itemDisplayLcd: {},
            barcode: "",
            isInputFocused: false,
            socketDisplay: null,
            orientationMediaQuery: null,
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
    },
    mounted() {
        this.checkCartStatus();
        this.getCustomers();
        setTimeout(() => {
            document.getElementById('scanProduct').focus();
        }, 500);
        this.socketDisplay = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        toggleCartLoader(state) {
            this.showLoaderCart = state;
        },
        checkCartStatus() {
            if (JSON.parse(localStorage.getItem("cart"))) {
                this.showLoaderCart = true;
                cartService.show(JSON.parse(localStorage.getItem("cart")).id).then((res) => {
                    this.cartObject = res.data;
                    this.cart = res.data.cart_items;
                    localStorage.setItem("cart", JSON.stringify(res.data))
                }).catch((error) => {
                    console.log("error api : ", error);
                    // this.showErrorNotification('Error fetching cart items')
                }).finally(() => {
                    this.showLoaderCart = false;
                });
            }
        },
        showDiscount() {
            this.modalDiscount.active = true;
        },
        showCoupon() {
            this.modalCoupon.active = true;
        },
        getCustomers() {
            customerService.getAll().then((response) => {
                this.customers = response.data;
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        // selectCustomer(item) {
        //     customerService.getAll().then((response) => {
        //         this.customers = response.data;
        //     }).catch((error) => {
        //         console.log("error api : ", error);
        //     });
        // },
        removeQuantity(product) {
            this.showLoaderCart = true;
            cartItemService.delete(this.cartObject.id, product.id).then((res) => {
                this.cartObject = res.data;
                this.cart = res.data.cart_items;
                notificationPlay.delete();
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoaderCart = false;
            });
        },
        addQuantity(product) {
            const index = this.findCartItem(product);
            this.cart[index].qty += 1;
            notificationPlay.beep();
        },
        emptyCart() {
            cartService.delete(this.cartObject.id).then((res) => {
                this.cartObject = {};
                this.cart = [];
                localStorage.removeItem("cart")
                localStorage.removeItem("customer")
                localStorage.removeItem("table")
                notificationPlay.delete();
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        selectItem(item) {
            if (this.showLoaderCart != true) {
                this.showLoaderCart = true;
                if (JSON.parse(localStorage.getItem("cart"))) {
                    cartService.show(JSON.parse(localStorage.getItem("cart")).id).then((res) => {
                        this.selectedItem = item;
                        this.openPopup();
                    }).catch((error) => {
                        let payload = {
                            user_id: JSON.parse(localStorage.getItem("user")).user.id,
                            device_id: JSON.parse(localStorage.getItem("device")).id
                        }
                        cartService.create(payload).then((res) => {
                            localStorage.setItem("cart", JSON.stringify(res.data))
                            this.cartObject = res.data;
                            this.cart = [];
                            this.selectedItem = item;
                            this.openPopup();
                        }).catch((error) => {
                            console.log("error api : ", error);
                        });
                    });
                } else {
                    let payload = {
                        user_id: JSON.parse(localStorage.getItem("user")).user.id,
                        device_id: JSON.parse(localStorage.getItem("device")).id
                    }
                    cartService.create(payload).then((res) => {
                        localStorage.setItem("cart", JSON.stringify(res.data))
                        this.cartObject = res.data;
                        this.selectedItem = item;
                        this.openPopup();
                    }).catch((error) => {
                        console.log("error api : ", error);
                    });
                }
            }
        },
        openPopup() {
            this.showLoaderCart = false;
            if (this.selectedItem.has_ingredients == 0)
                this.confirmItem();
            else
                this.modal.active = true;
        },
        confirmItem(additionalIngrediants = []) {
            this.showLoaderCart = true;
            let payload = {
                cart_id: JSON.parse(localStorage.getItem("cart")).id,
                product_id: this.selectedItem.id,
                quantity: "1"
            }
            if (additionalIngrediants[0]?.id)
                payload.additional_ingredients = JSON.stringify({ ingredients: additionalIngrediants });
            console.log('dfffffff, ', this.selectedQty)
            if (this.selectedQty.length != 0)
                payload.quantity = this.selectedQty;
            cartItemService.create(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
                this.itemDisplayLcd = this.selectedItem;
                this.cartObject = res.data;
                this.cart = res.data.cart_items;
                this.closePopup();
                notificationPlay.beep();
                this.sendMessage();
            }).catch((error) => {
                console.log("error api : ", error);
                if (error.message == "Insufficient quantity in stock") {
                    this.modalInfo.active = true;
                    this.remainingQty = error.remaining_quantity;
                }
            }).finally(() => {
                this.showLoaderCart = false;
                this.selectedQty = "";
            });
        },
        findCartIndex(product) {
            return this.cart.findIndex((p) => p.productId === product.id);
        },
        findCartItem(product) {
            return this.cart.findIndex((p) => p.productId === product.productId);
        },
        showReceipt() {
            this.modal.active = true;
        },
        hideReceipt() {
            this.modal.active = false;
        },
        deleteCartVar() {
            this.cartObject = {};
            this.cart = [];
        },
        closePopup() {
            this.modal.active = false;
            this.modalQuantity.active = false;
            this.modalDiscount.active = false;
            this.modalCoupon.active = false;
        },
        createCustomer(customer) {
            customerService.create(customer).then((response) => {
                this.getCustomers();
                this.cancelCreate();
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        cancelCreate() {
            this.showAddCustomer = false;
        },
        goAddCustomer() {
            router.push("/add-customer")
        },
        selectCustomer(customer) {
            this.selectedCustomer = customer;
        },
        chooseCustomer() {
            if (JSON.parse(localStorage.getItem("cart"))) {
                cartService.show(JSON.parse(localStorage.getItem("cart")).id).then((res) => {
                    this.assignOrder();
                }).catch((error) => {
                    this.placeOrder();
                }).finally(() => {
                    // 
                });
            } else {
                this.placeOrder();
            }
        },
        searchCustomers(filter) {
            customerService.filter("name", filter).then((response) => {
                this.customers = response.data;
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        showSales() {
            if (this.showSalesCustomer) {
                this.showSalesCustomer = false;
            } else {
                customerService.getById(this.selectedCustomer.id).then((response) => {
                    this.selectedCustomer = response.data;
                    this.showSalesCustomer = true;
                }).catch((error) => {
                    console.log("error api : ", error);
                });
            }
        },
        updateCustomerStorage() {
            localStorage.setItem("customer", JSON.stringify(this.selectedCustomer))
        },
        assignOrder() {
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                customer_id: this.selectedCustomer.id
            }
            cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
                this.updateCustomerStorage();
                router.push("/home");
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                // 
            });
        },
        placeOrder() {
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                customer_id: this.selectedCustomer.id,
                device_id: JSON.parse(localStorage.getItem("device")).id
            }
            cartService.create(payload).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
                this.updateCustomerStorage();
                router.push("/home");
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        confirmDiscount(amount, type) {
            this.closePopup();
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                discount_type: type,
                discount_value: type == "amount" ? amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : amount
            }
            cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
                this.cartObject = res.data;
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                // 
            });
        },
        confirmCoupon(code) {
            this.closePopup();
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                coupon_code: code
            }
            cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
                if (res.data) {
                    localStorage.setItem("cart", JSON.stringify(res.data))
                    this.cartObject = res.data;
                    this.showNotification("Coupon successful");
                } else {
                    this.showErrorNotification(res.message);
                }
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                // 
            });
        },
        confirmBarcode() {
            if (this.barcode.length != 0) {
                setTimeout(() => {
                    productService.getProductsFilter("barcode", this.barcode, '&warehouse_id=' + this.posConfig.warehouse_id).then((res) => {
                        this.selectItem(res.data[0]);
                    }).catch((error) => {
                        console.log("error api : get products", error);
                    }).finally(() => {
                        this.barcode = "";
                    });
                }, 150);
            }
        },
        enableScanmode() {
            document.getElementById('scanProduct').focus();
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socketDisplay = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socketDisplay.onopen = (event) => {
                this.socketDisplay.send(token); // send the token as soon as the connection is established
            };

            this.socketDisplay.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socketDisplay.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socketDisplay.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socketDisplay.readyState === WebSocket.OPEN) {
                const message = {
                    action: "display_item",
                    data: this.itemDisplayLcd
                };
                this.socketDisplay.send(JSON.stringify(message));
                this.loadingPrinting = false;
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        },
    }
};
</script>

<style scoped></style>