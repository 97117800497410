<template>
    <div>
        <form @submit.prevent="submitForm" class="bg-[#fff] rounded-lg px-6 py-5 border-2 border-b-gray-300">

            <div class="input-group-col">
                <div class="column-col">
                    <label for="fname" class="label-col">{{ $t("Pos.FName") }}</label>
                    <a-input type="text" id="fname" v-model="customer.first_name" @click="selectInput('fname')" @input="validateFieldsAction" class="input-box-col"
                    :placeholder="$t('Pos.FName')" />
                </div>

                <div class="column-col">
                    <label for="lname" class="label-col">{{ $t("Pos.LName") }}</label>
                    <a-input type="text" id="lname" @click="selectInput('lname')" @input="validateFieldsAction" class="input-box-col"
                    :placeholder="$t('Pos.LName')" />
                </div>
            </div>

            <div class="input-group">
                <label for="phone" class="label-col">{{ $t("Pos.PhoneNumber") }}</label>
                <a-input type="text" id="phone" @click="selectInput('phone')" @input="validateFieldsAction" class="input-box"
                :placeholder="$t('Pos.PhoneNumber')" />
            </div>
            <div class="input-group ">
                <label for="email" class="label-col">{{ $t("Pos.Email") }}</label>
                <a-input type="text" id="email" @click="selectInput('email')" class="input-box"
                :placeholder="$t('Pos.Email')" />
            </div>

            <div class="input-group-col">
                <div class="column-col">
                    <label for="city" class="label-col">{{ $t("Pos.Address") }}</label>
                    <a-input type="text" id="address" @click="selectInput('address')" class="input-box-col"
                    :placeholder="$t('Pos.Address')" />
                </div>

                <div class="column-col">
                    <label for="input2" class="label-col">{{ $t("Pos.ShippingAddress") }}</label>
                    <a-input type="text" id="ShippingAddress" @click="selectInput('ShippingAddress')"
                        class="input-box-col" :placeholder="$t('Pos.ShippingAddress')" />
                </div>
            </div>


            <div class="input-group-col">
                <div class="column-col">
                    <label for="input1" class="label-col">{{ $t("Pos.BillingAddress") }}</label>
                    <a-input type="text" id="BillingAddress" @click="selectInput('BillingAddress')"
                        class="input-box-col" :placeholder="$t('Pos.BillingAddress')" />
                </div>

                <div class="column-col">
                    <label for="input2" class="label-col">{{ $t("Pos.ContactName") }}</label>
                    <a-input type="text" id="ContactName" @click="selectInput('ContactName')" class="input-box-col"
                    :placeholder="$t('Pos.ContactName')" />
                </div>
            </div>



            <div class="input-group-col">
                <div class="column-col">
                    <label for="input1" class="label-col">{{ $t("Pos.CompanyName") }}</label>
                    <a-input type="text" id="CompanyName" @click="selectInput('CompanyName')" class="input-box-col"
                    :placeholder="$t('Pos.CompanyName')" />
                </div>

                <div class="column-col">
                    <label for="input2" class="label-col">{{ $t("Pos.VatId") }}</label>
                    <a-input type="text" id="VatId" @click="selectInput('VatId')" class="input-box-col"
                    :placeholder="$t('Pos.VatId')" />
                </div>
            </div>

            <div class="label-button-container">
                <a-button @click="cancelCreate" type="text"
                    style="display: flex; align-items: center; font-size: 16px; height: auto; padding: 0 20px; margin-right: 15px;">
                    <template #icon>
                        <EditOutlined :style="{ fontSize: '20px' }" />
                    </template>
                    {{ $t("Pos.Cancel") }}
                </a-button>
                <a-button :disabled="disabled"
                    class="text-white font-semibold bg-primary py-6 px-8 flex items-center border-none"
                    @click="submitForm" style="color: #fff;">
                    <template #icon>
                        <iconPlus :size="20" :color="'#fff'" class="mx-2" />
                    </template>
                    {{ $t("Pos.AddCustomer") }}
                </a-button>
            </div>
        </form>
        <LbrxKeyboardPopup :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" :selectedText="selectedText" />
    </div>
</template>

<script>
import LbrxKeyboardPopup from "./LbrxKeyboardPopup.vue";
import iconPlus from "./icons/iconPlus.vue"
export default {
    name: "PlusOutlined",
    components: {
        iconPlus,
        LbrxKeyboardPopup
    },
    data() {
        return {
            greetingText: 'Hello',
            customer: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                address: '',
                vat_id: '',
                contact_name: '',
                billing_address: '',
                shipping_address: '',
                company_name: ''
            },
            selectedId: "",
            modalKeyboard: {
                active: false
            },
            selectedText: "",
            disabled: true
        };
    },
    props: {
        posConfig: {
            required: true,
            value: Object
        }
    },
    computed: {
        validateFields() {
            return !(document.getElementById("fname")?.value !== '');
        },
    },
    methods: {
        validateFieldsAction() {
            this.disabled = !(document.getElementById("fname")?.value !== '' && document.getElementById("lname")?.value !== '' && document.getElementById("phone")?.value !== '' && document.getElementById("phone")?.value.length >= 8);
        },
        submitForm() {
            this.$emit("createCustomer", {
                first_name: document.getElementById("fname")?.value,
                last_name: document.getElementById("lname")?.value,
                email: document.getElementById("email")?.value,
                phone: document.getElementById("phone")?.value,
                address: document.getElementById("address")?.value,
                vat_id: document.getElementById("VatId")?.value,
                contact_name: document.getElementById("address")?.value,
                billing_address: document.getElementById("addreBillingAddressss")?.value,
                shipping_address: document.getElementById("ShippingAddress")?.value,
                company_name: document.getElementById("CompanyName")?.value
            });
        },
        cancelCreate() {
            this.$emit("cancelCreate");
        },
        // getInput(event) {
        //     const inputValue = event.target.value;
        //     this.suid = inputValue.value;
        // },
        selectInput(id) {
            const inputValue = document.getElementById(id);
            this.selectedText = inputValue.value;
            this.selectedId = id;
            if (this.posConfig.posConfig.showKeyboard == 1) {
                this.modalKeyboard.active = true;
                
            }
        },
        closePopupKeyboard(text) {
            this.$nextTick(() => {
            const inputValue = document.getElementById(this.selectedId);
            inputValue.value = text.toLowerCase();
            this.modalKeyboard.active = false;
            this.validateFieldsAction();
        })
        }
    }
};
</script>

<style scoped>
.blue-button {
    background-color: #008FCA;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 25px 40px;
}

.input-group-col {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    gap: 1.5rem;
    /* Adjust as needed */
}


.input-box-col {
    flex: 1;
    border: 2px solid #79767648;
    padding: 8px;
    border-radius: 5px;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input-group label {
    font-size: 15px;
    align-self: flex-start;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-box {
    border: 2px solid #79767648;
    padding: 8px;
    border-radius: 5px;
}

.column-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* Take all available space */
    /* Adjust as needed for space between columns */
}


.label-col {
    color: black;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-start;
}


/* Styles for the common container */
.label-button-container {
    display: flex;
}

/* Add your component-specific styles here if needed */
</style>