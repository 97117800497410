<template>
    <div class="h-full flex flex-col">
        <div class="flex flex-col gap-3 mb-3">
            <span class="opacity-50 font-semibold">{{ $t('Pos.PinCode') }}</span>
            <input id="inputBar" class="py-2 text-center text-3xl border-2 border-b-gray-300 font-extrabold"
                type="password" :value="pinCode" style="-webkit-text-security: circle;" @input="getCode">
        </div>
        <div class="pad-container flex-1 gap-1 font-semibold text-4xl">
            <div v-for="(index) in 9" :key="index" @click="clickPad(index)"
                class="bg-white w-[9rem] px-12 flex items-center justify-center border-2 border-b-gray-300" 
                :class="isPortrait ? 'py-12' : ''" >
                <div>{{ index }}</div>
            </div>
            <div @click="clickPad(-2)"
                class="bg-white w-[9rem] flex items-center justify-center border-2 border-b-gray-300">
                {{ '<<' }} </div>
                    <div @click="clickPad(0)" @pointerdown="startCountdown(true, item)"
                        @pointerup="startCountdown(false)"
                        class="bg-white w-[9rem] flex items-center justify-center border-2 border-b-gray-300" :class="isPortrait ? 'py-12' : ''">
                        <div style="margin:auto">0</div>
                    </div>
                    <div @click="clickPad(-1)"
                        class="bg-white w-[9rem] flex items-center justify-center border-2 border-b-gray-300" :class="isPortrait ? 'py-12' : ''">
                        C
                    </div>
            </div>
            <!-- <div class="flex justify-center">
            <button class="bg-[#09aa29] flex justify-center rounded-full p-6 mt-5"><img src="../assets/icons/tick.svg" class="w-10" alt=""></button>
        </div> -->
        </div>
</template>

<script>
import { authService } from '@/_services';
export default {
    name: "LbrxLoginPad",
    components: {
    },
    data() {
        return {
            timer: null
        };
    },
    props: {
        pinCode: {
            required: true,
            value: String
        },
        isPortrait: {
            required: false,
            value: Boolean,
            default: false
        }
    },
    computed: {
        getNumPrimary() {
            return '#000'
        },
        getNumSecondary() {
            return '#000'
        },
        getnumBackground() {
            return '#000'
        },
    },
    methods: {
        clickPad(num) {
            this.$emit("clickPad", num);
        },
        getCode() {
            const inputValue = document.getElementById("inputBar");
            this.$emit("checkInput", inputValue.value);
        },
        startCountdown(param, item = {}) {
            if (param) {
                this.timer = setTimeout(() => {
                    this.logout();
                }, 8000); // 2 seconds
            } else {
                clearTimeout(this.timer);
                console.log("Countdown disabled.");
            }
        },
        logout() {
            // authService.logout().then(() => {
            localStorage.removeItem("user");
            localStorage.removeItem("device");
            localStorage.removeItem("themeTemplate");
            localStorage.removeItem("cart");
            localStorage.removeItem("sale");
            location.reload();
            // }).catch((err) => {
            //     console.log(err)
            // }).finally(() => {
            //     console.log('finish')
            // })
        },
    }
};
</script>

<style scoped>
input[type='password'] {
    font-family: Verdana;
    letter-spacing: 0.125em;
}

.pad-container {
    margin-top: 1.5vmin;
    justify-items: center;
    display: grid;
    grid-template-columns: auto auto auto;
}

.pad-element {

    height: 10vh;
    width: 10vh;
    cursor: pointer;
    background: v-bind(getnumBackground);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 1vmin;
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 7vh;
    line-height: 7vh;
    text-align: center;
    display: flex;

    color: v-bind(getNumPrimary);

}

.secondary {
    background: v-bind(getNumSecondary) !important;
    display: flex;
    padding: 1vh;
    justify-content: center;
}

.phone-number-input {
    background: v-bind(getnumBackground);
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 4.5vh;
    padding: 1vh;
    text-align: center;
    height: fit-content;
    width: 100%;
    color: v-bind(getNumPrimary);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none !important;
}

.phone-number-input::placeholder {
    font-weight: 600;
    font-size: 4.5vh;
    text-align: center;
    color: #A3A3A3;
}

.input-box {
    display: flex;
    height: 100%;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
}

.phone-number-input-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
</style>