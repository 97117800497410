<template>
  <LbrxLoaderBlur v-if="showLoader" />
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[670px] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow h-full mx-10 mt-2">
        <div class="flex justify-between">
          <img class="w-40" :src="selectedItem.photo" alt="">
          <div class="flex flex-col justify-center flex-grow my-6 mx-5">
            <div class="flex justify-between items-baseline text-left">
              <span class="text-xl font-bold">{{ selectedItem.name }}</span>
              <span class="text-lg font-semibold text-nowrap">{{ productPriceFormat + " " + currencyCode }}</span>
            </div>
            <div class="flex flex-wrap gap-x-4 my-2 ">
              <span class="text-nowrap text-sm">{{ selectedItem.description }}</span>
            </div>
          </div>
        </div>
        <div class="flex mb-2 gap-x-2 gap-y-2 flex-wrap mt-2">
          <div v-for="(item, index) in checkedItems" :key="index" class="my-1">
            <span @click="remove(item)"
              class="border-2 border-primary rounded-2xl pb-[6px] pt-1 px-3 my-4 text-primary text-center items-center cursor-pointer">{{
                item.name }}</span>
          </div>
        </div>

        <div class="flex flex-col items-start mb-3 overflow-auto flex-grow">

          <div class="w-full text-left">
            <a-card v-for="(item, index) in selectedItem.variants_by_attribute_type" :key="index" :title="item.name"
              :bordered="false">
              <div class="flex gap-x-3 flex-wrap">
                <div v-for="(itemAttribute, index) in item.attribute_values" :key="index" class="flex cursor-pointer"
                  @click="selectItem(itemAttribute)">
                  <span class="border-2 border-primary rounded-2xl py-1 px-3"
                    :class="findItemIndex(itemAttribute) != -1 ? 'bg-primary text-white' : 'text-primary'">{{
                      itemAttribute.name }}</span>
                </div>
              </div>
            </a-card>
            <!-- <a-card title="Taille" :bordered="false">
                <div class="flex gap-x-3 flex-wrap">
                  <div v-for="(itemVar, index) in taille" :key="index" class="flex cursor-pointer my-1"
                    @click="selectItem(itemVar)">
                    <span class="border-2 border-primary rounded-2xl py-1 min-w-9 px-2"
                      :class="findItemIndex(itemVar) != -1 ? 'bg-primary text-white' : 'text-primary'">{{
                        itemVar.name }}</span>
                  </div>
              </div>
            </a-card> -->
          </div>
        </div>

        <div>
          <span v-if="!productExists" class="text-red-600">{{ $t('Pos.CombinationNotAvailable') }}</span>
          <button :disabled="getDisabledButton" class="w-full py-3 rounded-lg my-2 text-white text-xl font-semibold"
            :class="getDisabledButton ? 'bg-gray-400' : 'bg-confirmation'" @click="confirmItem">{{ $t('Pos.Confirm')
            }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import { productService } from '@/_services';
import moment from "moment/moment";
export default {
  name: "LbrxCustomizeVariantPopup",
  components: {
    LbrxLoaderBlur,
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      couleur: [{ name: "Noir" }, { name: "Blanc" }, { name: "Rouge" }, { name: "Jaune" }, { name: "Bleu marine" }],
      taille: [{ name: "XS" }, { name: "S" }, { name: "M" }, { name: "L" }, { name: "XL" }, { name: "XXL" }, { name: "XXXL" }],
      showLoader: false,
      productExists: true,
      product: {}
    };
  },
  props: {
    selectedItem: {
      required: true,
      value: Object
    },
    active: {
      required: true,
      value: Boolean
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    },
    currencyCode: {
      required: false,
      value: String
    },
  },
  computed: {
    productPriceFormat() {
      return this.selectedItem.price.toFixed(this.decimalPrecision);
    },
    variants() {
      return this.checkedItems.map(el => el.id).join(",")
    },
    getDisabledButton() {
      if(this.checkedItems.length == this.selectedItem.variants_by_attribute_type.length && this.productExists) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    closePopup() {
      this.reset();
      this.$emit("closePopup")
    },
    reset() {
      this.checkedItems = [];
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmItem() {
      this.$emit("confirmItemVariant", this.product);
    },
    // selectItem(item) {
    //   console.log('dfdsfdsf', item)
    //   if (item.qty)
    //     item.qty += 1;
    //   else {
    //     item.qty = 1;
    //     this.checkedItems.push(item);
    //   }
    // },
    selectItem(item) {
      const currentItem = item;
      const indexAttribute = this.checkAttributeId(currentItem);
      if (indexAttribute === -1) {
        this.checkedItems.push(item);
      } else {
        this.checkedItems = this.checkedItems.filter(function (el) {
          return el.attribute_id !== currentItem.attribute_id;
        });
        this.checkedItems.push(currentItem);
      }
      if (this.checkedItems.length != 0) {
        this.showLoader = true;
        productService.getVariantByAttribute(this.selectedItem.id, '?attribute_values=' + this.checkedItems.map(el => el.id).join(",")).then((res) => {
          this.product = res.data;
          this.productExists = true;
        }).catch((error) => {
          this.productExists = false;
        }).finally(() => {
          this.showLoader = false;
        });
      }
    },
    findItemIndex(item) {
      return this.checkedItems.findIndex((el) => el === item);
    },
    checkAttributeId(item) {
      return this.checkedItems.findIndex((el) => el.attribute_id === item.attribute_id);
    },
    assignDefaultIngr(item) {
      this.checkedItems = item;
    },
    remove(item) {
      if (item.qty > 1) {
        item.qty -= 1;
      } else {
        this.checkedItems = this.checkedItems.filter(function (el) {
          return el !== item;
        });
      }
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>