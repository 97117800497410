<template>
    <div class="flex flex-col flex-grow mx-4" :class="isPortrait ? 'h-[50vh] mb-4' : 'w-[58%]'">
        <div class="flex my-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ $t('Pos.UpdateCustomer') }}
            </span>
        </div>
        <div class="h-full overflow-hidden">
            <form class="bg-[#fff] rounded-lg px-6 py-5 border-2 border-b-gray-300">

                <div class="input-group-col">
                    <div class="column-col">
                        <label for="fname" class="label-col">{{ $t("Pos.FName") }}</label>
                        <a-input v-model:value="selectedCustomer.first_name" @click="selectInput('fname')" @input="validateFieldsAction" type="text" id="fname" class="input-box-col"
                            :placeholder="$t('Pos.FName')" />
                    </div>

                    <div class="column-col">
                        <label for="lname" class="label-col">{{ $t("Pos.LName") }}</label>
                        <a-input v-model:value="selectedCustomer.last_name" @click="selectInput('lname')" @input="validateFieldsAction" type="text" id="lname" class="input-box-col"
                        :placeholder="$t('Pos.LName')" />
                    </div>
                </div>

                <div class="input-group">
                    <label for="phone" class="label-col">{{ $t("Pos.PhoneNumber") }}</label>
                    <a-input v-model:value="selectedCustomer.phone" @click="selectInput('phone')" @input="validateFieldsAction" type="text" id="phone" class="input-box"
                    :placeholder="$t('Pos.PhoneNumber')" />
                </div>
                <div class="input-group ">
                    <label for="email" class="label-col">{{ $t("Pos.Email") }}</label>
                    <a-input v-model:value="selectedCustomer.email" @click="selectInput('email')" type="text" id="email" class="input-box"
                        :placeholder="$t('Pos.Email')" />
                </div>

                <div class="input-group-col">
                    <div class="column-col">
                        <label for="city" class="label-col">{{ $t("Pos.Address") }}</label>
                        <a-input v-model:value="selectedCustomer.address" @click="selectInput('address')" type="text" id="address" class="input-box-col"
                        :placeholder="$t('Pos.Address')" />
                    </div>

                    <div class="column-col">
                        <label for="input2" class="label-col">{{ $t("Pos.ShippingAddress") }}</label>
                        <a-input v-model:value="selectedCustomer.shipping_address" @click="selectInput('ShippingAddress')" type="text" id="ShippingAddress"
                            class="input-box-col" :placeholder="$t('Pos.ShippingAddress')" />
                    </div>
                </div>


                <div class="input-group-col">
                    <div class="column-col">
                        <label for="input1" class="label-col">{{ $t("Pos.BillingAddress") }}</label>
                        <a-input v-model:value="selectedCustomer.billing_address" @click="selectInput('BillingAddress')" type="text" id="BillingAddress"
                            class="input-box-col" :placeholder="$t('Pos.BillingAddress')" />
                    </div>

                    <div class="column-col">
                        <label for="input2" class="label-col">{{ $t("Pos.ContactName") }}</label>
                        <a-input v-model:value="selectedCustomer.contact_name"  @click="selectInput('ContactName')" type="text" id="ContactName"
                            class="input-box-col" :placeholder="$t('Pos.ContactName')" />
                    </div>
                </div>



                <div class="input-group-col">
                    <div class="column-col">
                        <label for="input1" class="label-col">{{ $t("Pos.CompanyName") }}</label>
                        <a-input v-model:value="selectedCustomer.company_name" @click="selectInput('CompanyName')" type="text" id="CompanyName"
                            class="input-box-col" :placeholder="$t('Pos.CompanyName')" />
                    </div>

                    <div class="column-col">
                        <label for="input2" class="label-col">{{ $t("Pos.VatId") }}</label>
                        <a-input v-model:value="selectedCustomer.vat_id" @click="selectInput('VatId')" type="text" id="VatId" class="input-box-col"
                        :placeholder="$t('Pos.VatId')" />
                    </div>
                </div>

                <div class="label-button-container">
                    <a-button @click="cancelCreate" type="text"
                        style="display: flex; align-items: center; font-size: 16px; height: auto; padding: 0 20px; margin-right: 15px;">
                        <template #icon>
                            <EditOutlined :style="{ fontSize: '20px' }" />
                        </template>
                        {{ $t("Pos.Cancel") }}
                    </a-button>
                    <a-button @click="updateCustomer" :disabled="disabled"
                        class="text-white font-semibold bg-primary py-6 px-8 flex items-center border-none"
                        style="color: #fff;"
                        >
                        <template #icon>
                            <iconEdit :size="20" :color="'#fff'" class="mx-2" />
                        </template>
                        {{ $t('Pos.UpdateCustomer') }}
                    </a-button>
                </div>
            </form>
        </div>
    </div>
    <div v-if="!isPortrait" class="w-[32%]">
        <LbrxCart :cart="cart" @removeQuantity="removeQuantity" @addQuantity="addQuantity" @emptyCart="emptyCart"
            @showReceipt="showReceipt" :hideDiscountBar="true" :currencyCode="currencyCode" :pos="pos" :posConfig="posConfig" />
    </div>
    <LbrxKeyboardPopup ref="keyboardPopup" :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
</template>

<script>
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import { notification } from 'ant-design-vue';
import LbrxCart from "@/components/LbrxCart.vue"
import iconEdit from "@/components/icons/iconEdit.vue"
import { notificationPlay } from '../../_helpers'
import { customerService } from '@/_services';
import router from "@/router";
export default {
    name: "addCustomer",
    components: {
        LbrxCart,
        iconEdit,
        LbrxKeyboardPopup
    },
    data() {
        return {
            modal: {
                active: false
            },
            showAddCustomer: false,
            cart: [],
            selectedCustomer: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                address: '',
                vat_id: '',
                contact_name: '',
                billing_address: '',
                shipping_address: '',
                company_name: ''
            },
            selectedId: "",
            selectedText: "",
            modalKeyboard: {
                active: false
            },
            pos: {},
            posConfig: {},
            disabled: false,
            currency: {},
            currencyCode: "",
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => {return el.is_default == 1});
            if(this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
    },
    mounted() {
        this.getCustomerById();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        validateFields() {
            return !(this.selectedCustomer.first_name !== '' && this.selectedCustomer.last_name !== '' && this.selectedCustomer.phone !== '');
        }
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        validateFieldsAction() {
            this.disabled = !(document.getElementById("fname")?.value !== '' && document.getElementById("lname")?.value !== '' && document.getElementById("phone")?.value !== '' && document.getElementById("phone")?.value.length >= 8);
        },
        getCustomerById() {
            customerService.getById(this.$route.params.id).then((response) => {
                this.selectedCustomer = response.data;
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.validateFieldsAction();
            });
        },
        emptyCart() {
            this.cart = [];
            notificationPlay.delete();
        },
        updateCustomer() {
            let payload = {
                first_name: document.getElementById("fname")?.value,
                last_name: document.getElementById("lname")?.value,
                email: document.getElementById("email")?.value,
                phone: document.getElementById("phone")?.value,
                address: document.getElementById("address")?.value,
                vat_id: document.getElementById("VatId")?.value,
                contact_name: document.getElementById("address")?.value,
                billing_address: document.getElementById("addreBillingAddressss")?.value,
                shipping_address: document.getElementById("ShippingAddress")?.value,
                company_name: document.getElementById("CompanyName")?.value
            }
            customerService.update(this.selectedCustomer.id, payload).then((res) => {
                this.cancelCreate();
                this.showNotification(this.$t('Pos.UpdateSuccesful'));
            }).catch((error) => {
                this.showErrorNotification(this.$t('Pos.ErrorTry'));
                console.log("error api : ", error);
            });
        },
        cancelCreate() {
            router.push("/customers")
        },
        selectInput(id) {
            this.selectedText = document.getElementById(id).value;
            this.selectedId = id;
            if (this.posConfig.posConfig.showKeyboard == 1) {
                this.modalKeyboard.active = true;
                this.$refs.keyboardPopup.addSelectedInput(this.selectedText);
            }
        },
        closePopupKeyboard(text) {
            const inputValue = document.getElementById(this.selectedId);
            inputValue.value = text.toLowerCase();
            this.modalKeyboard.active = false;
            this.validateFieldsAction();
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        }
    }
};
</script>

<style scoped>
.blue-button {
    background-color: #008FCA;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 25px 40px;
}

.input-group-col {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    gap: 1.5rem;
    /* Adjust as needed */
}


.input-box-col {
    flex: 1;
    border: 2px solid #79767648;
    padding: 8px;
    border-radius: 5px;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input-group label {
    font-size: 15px;
    align-self: flex-start;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-box {
    border: 2px solid #79767648;
    padding: 8px;
    border-radius: 5px;
}

.column-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* Take all available space */
    /* Adjust as needed for space between columns */
}


.label-col {
    color: black;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-start;
}


/* Styles for the common container */
.label-button-container {
    display: flex;
}
</style>