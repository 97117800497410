import {authHeader, ApiConfigs} from "../_helpers";
// import store from '@/store';

export const customerService = {
    getAll,
    filter,
    getById,
    create,
    update,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.getAll}`, requestOptions).then(handleResponse);
}

function filter(key: string, filter: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.getAll}?per_page=15&current_page=1&key=${key}&value=${filter}`, requestOptions).then(handleResponse);
}

function create(payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.store}`, requestOptions).then(handleResponse);
}

function getById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.getById.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.customer.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem("user");
                location.reload();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
