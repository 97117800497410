<template>
    <div class="w-full flex flex-col flex-grow px-4 overflow-y-auto">
        <LbrxLoaderBlur v-if="showLoader" />
        <LbrxFilterBar :filters="filters" :selectedFilter="selectedFilter" @selectFilter="selectFilter"
            :showOpenDrawer="true" @openDrawer="openDrawer" class="my-4" />
        <div class="mb-4">
            <LbrxListSales v-if="selectedFilter.id == 3" :sales="sales" @selectSale="selectSale"
                @searchSales="searchSales" :totalItems="totalItems" :perPage="perPage"
                @handlePageChange="handlePageChange" :currencyCode="currencyCode"
                :decimalPrecision="posConfig.posConfig.decimalPrecision" />
            <LbrxTodaysSales v-else-if="selectedFilter.id == 2" :salesToday="salesToday" :cashDrawer="cashDrawer"
                @selectSale="selectSale" @searchSales="searchSales" :totalItems="totalItemsD" :perPage="perPage"
                :decimalPrecision="posConfig.posConfig.decimalPrecision" @handlePageChange="handlePageChangeDate"
                :currencyCode="currencyCode" :isPortrait="isPortrait" />
            <LbrxCashDrawer v-else @showNotification="showNotification" @setCashDrawer="setCashDrawer"
                :currencyCode="currencyCode" :decimalPrecision="posConfig.posConfig.decimalPrecision"
                :posConfig="posConfig" />
        </div>
    </div>
    <!-- <div v-if="selectedFilter.id == 1" class="w-[32%]">

    </div> -->
</template>

<script>
import LbrxListSales from "@/components/LbrxListSales.vue"
import LbrxTodaysSales from "@/components/LbrxTodaysSales.vue"
import LbrxCashDrawer from "@/components/LbrxCashDrawer.vue"
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import { notification } from 'ant-design-vue';
import { saleService } from "@/_services"
import moment from "moment/moment";
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import webSocketService from "@/_services/webSocketService";

export default {
    name: "cashierIndex",
    components: {
        LbrxListSales,
        LbrxTodaysSales,
        LbrxCashDrawer,
        LbrxFilterBar,
        LbrxLoaderBlur
    },
    data() {
        return {
            modal: {
                active: false
            },
            sales: [],
            salesToday: [],
            selectedFilter: {},
            selectedSale: {},
            currentPage: 1,
            currentPageD: 1,
            totalItems: 1,
            totalItemsD: 1,
            perPage: 15,
            cashDrawer: {},
            socket: null,
            filters: [{ name: this.$t('Pos.CashDrawer'), id: 1 }, { name: this.$t('Pos.TodaysSale'), id: 2 }, { name: this.$t('Pos.SaleHistory'), id: 3 }],
            showLoader: false,
            currency: {},
            currencyCode: "",
            pos: {},
            posConfig: {},
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        // this.getSales(this.currentPage, this.perPage);
        // this.getSalesByDate(moment().format('YYYY-MM-DD'), this.currentPageD, this.perPage);
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
    },
    mounted() {
        this.selectedFilter = this.filters[0];
        this.socket = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        selectSale(item) {
            this.selectedSale = item;
        },
        findCartIndex(product) {
            return this.cart.findIndex((p) => p.productId === product.id);
        },
        findCartItem(product) {
            return this.cart.findIndex((p) => p.productId === product.productId);
        },
        selectFilter(item) {
            this.selectedFilter = item;
            if (item.id == 2) {
                this.getSalesByDate(moment().format('YYYY-MM-DD'), this.currentPageD, this.perPage);
            } else if (item.id == 3) {
                this.getSalesByCategory(this.currentPage, this.perPage);
            }
        },
        // getSales(currentPage, perPage) {
        //     this.showLoader = true;
        //     saleService.getAll(currentPage, perPage).then((res) => {
        //         this.sales = res.data;
        //         this.totalItems = res.meta.total;
        //     }).catch((error) => {
        //         console.log("error api : get products", error);
        //     }).finally(() => {
        //         this.showLoader = false;                
        //     });
        // },
        getSalesByCategory(currentPage, perPage) {
            this.showLoader = true;
            saleService.getByCategory("&user_id=" + JSON.parse(localStorage.getItem("user")).user.id, currentPage, perPage).then((res) => {
                this.sales = res.data;
                this.totalItems = res.meta.total;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        getSalesByDate(value, currentPageD, perPage) {
            this.showLoader = true;
            saleService.getByCategory("&user_id=" + JSON.parse(localStorage.getItem("user")).user.id + "&key=date&date=" + value, currentPageD, perPage).then((res) => {
                this.salesToday = res.data;
                this.totalItemsD = res.meta.total;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        searchSales(filter) {
            if (filter.length == 0) {
                this.getSalesByCategory();
            } else {
                saleService.filter(filter).then((res) => {
                    this.sales = res.data;
                }).catch((error) => {
                    console.log("error api : ", error);
                });
            }
        },
        handlePageChange(currentPage) {
            this.getSalesByCategory(currentPage, this.perPage);
        },
        handlePageChangeDate(currentPage) {
            this.getSalesByDate(moment().format('YYYY-MM-DD'), currentPage, this.perPage);
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        setCashDrawer(item) {
            this.cashDrawer = item;
        },
        openDrawer() {
            this.sendMessage();
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socket = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socket.onopen = (event) => {
                this.socket.send(token); // send the token as soon as the connection is established
            };

            this.socket.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socket.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socket.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socket.readyState === WebSocket.OPEN) {
                const message = {
                    action: "open_drawer"
                };
                this.socket.send(JSON.stringify(message));
                this.showNotification(this.$t('Pos.DrawerOpened'));
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        }
    }
};
</script>

<style scoped></style>