<template>
  <div class="overflow-hidden h-full flex flex-col border-l-2 border-b-gray-300 relative">
    <LbrxLoaderSpinner :isLoaded="showLoaderCart" />
    <div class="bg-[#fff] flex justify-between py-3 px-2 font-semibold">
      <div>
        <div class="flex items-center" v-if="customer.id">
          <div class="flex items-center">
            <img class="w-10 h-10 rounded-full" src="/default_img.jpg" alt="">
            <div class="flex flex-col ml-3 items-start">
              <span>{{ customer.first_name + " " + customer.last_name }}</span>
              <span>#{{ customer.id }}</span>
            </div>
          </div>
          <div class="mx-2 px-3 py-2">
            <CloseCircleOutlined :style="{ fontSize: '19px' }" />
          </div>
        </div>
        <button v-else @click="showSelectSale"
          class="h-full px-2 rounded-md bg-[#ebeef0] items-center justify-center text-[#141B34]">
          <div class="gap-2 items-center hidden xl:flex"><iconPlus :size="20" :color="'#141B34'" /><span>{{ $t('Pos.SelectSale') }}</span></div>
          <div class="flex gap-2 items-center xl:hidden"><DollarCircleOutlined /></div>
        </button>
      </div>
      <div class="flex gap-3">
        <button class="p-2 rounded-md" :class="isInputFocused ? 'bg-primary' : 'bg-[#f4f4f4]'" @click="enableScanmode">
          <iconBarcode :size="20" :color="isInputFocused ? '#fff' : '#141B34'" />
        </button>
        <button class="p-2 rounded-md bg-[#f4f4f4]" @click="gatCartItems"><img src="../assets/icons/refresh.svg"
            class="w-5" alt=""></button>
        <button class="p-2 rounded-md bg-[#f4f4f4]" @click="emptyCart">
          <iconTrash :size="20" :color="'#141B34'" />
        </button>
      </div>
    </div>
    <div class="bg-gray-200 h-[1px]"></div>
    <div v-if="cart.length == 0" class="bg-[#fff] flex flex-col h-full justify-center">
      <div class="opacity-20">
        <iconCart class="w-20 h-fit mx-auto" :size="24" :color="'#000'" />
        <span>{{ $t('Pos.CartEmpty') }}</span>
      </div>
    </div>
    <div v-if="cart.length"
      class="bg-[#fff] flex flex-col flex-1 space-y-1 px-2 py-4 overflow-y-auto overflow-x-hidden">
      <div v-for="(item, index) in cart" :key="index">
        <LbrxCartItem :item="item" :index="index" :bgColor="index % 2 == 0 ? '#f5f5f5' : '#fff'"
          @removeQuantity="removeQuantity" @addQuantity="addQuantity" @pointerdown="startCountdown(true, item)"
          @pointerup="startCountdown(false)" />
      </div>
    </div>
    <!-- <div class="bg-[#fff]">
      <div class="bg-[#fff2e8] flex justify-between m-2 p-3 font-semibold text-sm rounded-md">
        <span>{{ $t('Pos.Add') }}</span>
        <div class="flex gap-3 text-[#fc8019]">
          <button @click="showDiscount">{{ $t('Pos.Discount') }}</button>
          <button @click="showCoupon">{{ $t('Pos.CouponCode') }}</button>
          <button>{{ $t('Pos.Note') }}</button>
        </div>
      </div>
    </div> -->
    <div class="flex flex-col mx-3 my-2">
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Subtotal') }}</span><span class="font-semibold">{{ formatAmount(cartObject?.sub_total ?
          cartObject.sub_total : '0', currencyCode, decimalPrecision) }}</span>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Tax') }}</span><span class="font-semibold">{{ formatAmount(cartObject?.total_vat ?
          cartObject.total_vat : '0', currencyCode, decimalPrecision) }}</span>
      </div>
      <div class="flex justify-between text-xl font-semibold mt-2">
        <span>{{ $t('Pos.RefundAmount') }}</span><span>{{ formatAmount(cartObject?.total ? cartObject.total : '0',
          currencyCode, decimalPrecision)
          }}</span>
      </div>
    </div>
    <div class="flex mx-2 my-3 gap-2">
      <button @click="proceedRefund('coupon')"
        class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :disabled="calculateAmountDifference < 0 || cart.length == 0"><img src="../assets/icons/coupon.svg" class="w-5"
          alt="">{{ $t('Pos.GenerateCoupon') }}</button>
      <button @click="proceedRefund('cash')"
        class="bg-[#09aa29] flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :disabled="calculateAmountDifference < 0 || cart.length == 0"><img src="../assets/icons/proceed.svg" class="w-5"
          alt="">{{ $t('Pos.ReturnCash') }}</button>
    </div>
  </div>
</template>

<script>
import iconCart from "./icons/iconCart.vue"
import iconPlus from "./icons/iconPlus.vue"
import iconBarcode from "./icons/iconBarcode.vue"
import iconTrash from "./icons/iconTrash.vue"
import LbrxCartItem from "./LbrxCartItem.vue"
import LbrxLoaderSpinner from "./LbrxLoaderSpinner.vue"
import { CloseCircleOutlined, DollarCircleOutlined } from '@ant-design/icons-vue';
import { currency, notificationPlay } from '../_helpers';
import { notification } from 'ant-design-vue';
import { returnService } from "@/_services"
import router from "@/router";
import moment from "moment/moment";
export default {
  name: "LbrxCartRefund",
  inject: ['longPressTimer'],
  components: {
    iconCart,
    iconTrash,
    LbrxCartItem,
    iconPlus,
    LbrxLoaderSpinner,
    CloseCircleOutlined,
    DollarCircleOutlined,
    iconBarcode
  },
  data() {
    return {
      amountToGive: 0,
      customer: {}
    };
  },
  created() {
    // if (localStorage.getItem('customer')) {
    //   if (JSON.parse(localStorage.getItem("cart"))) {
    //     if (JSON.parse(localStorage.getItem("cart")).customer_id == JSON.parse(localStorage.getItem('customer')).id)
    //       this.customer = JSON.parse(localStorage.getItem('customer'));
    //     else {
    //       localStorage.removeItem('customer');
    //       this.customer = {};
    //     }
    //   } else {
    //     this.customer = JSON.parse(localStorage.getItem('customer'));
    //   }
    // }
  },
  computed: {
    totalPriceCart() {
      return this.cart.map(element => element.price * element.qty).reduce((a, b) => a + b, 0);
    },
    totalQuantityCart() {
      return this.cart.map(element => 1 * element.qty).reduce((a, b) => a + b, 0);
    },
    calculateAmountDifference() {
      let difference = this.amountToGive - this.totalPriceCart;
      if (difference >= 0) {
        return difference.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        return difference.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    }
  },
  props: {
    cart: {
      required: true,
      value: Array
    },
    cartObject: {
      required: true,
      value: Object
    },
    showLoaderCart: {
      required: true,
      value: Boolean
    },
    currencyCode: {
      required: true,
      value: String
    },
    isInputFocused: {
      required: false,
      value: Boolean
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    }
  },
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
      return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    removeQuantity(product) {
      this.$emit('removeQuantity', product);
    },
    addQuantity(product) {
      this.$emit('addQuantity', product);
    },
    emptyCart() {
      this.$emit("emptyCart");
    },
    addAmount(amount) {
      this.amountToGive += amount;
      notificationPlay.beep();
    },
    deleteAmount() {
      this.amountToGive = 0;
      notificationPlay.delete();
    },
    showReceipt() {
      this.$emit("showReceipt");
    },
    gatCartItems() {
      this.$emit("gatCartItems");
    },
    goAddCustomer() {
      router.push("/customers");
    },
    proceedRefund(option) {
      this.$emit("toggleCartLoader", true);
      let payload = {
        cart_id: JSON.parse(localStorage.getItem("refund_cart")).id.toString(),
        refundable_option: option,
        reason: "other",
        status: "completed",
        end_date: moment().add(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
      }
      returnService.create(payload).then((res) => {
        this.$emit("deleteCartVar");
        this.showNotification(this.$t('Pos.RefundSuccess'));
        localStorage.removeItem("refund_cart");
        this.$emit("sendRefundSocket", res.data);
      }).catch((error) => {
        console.log("error api : ", error);
      }).finally(() => {
        this.$emit("toggleCartLoader", false);
      });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'

        }
      });
    },
    showSelectSale() {
      this.$emit("showSelectSale")
    },
    showDiscount() {
      this.$emit("showDiscount")
    },
    showCoupon() {
      this.$emit("showCoupon")
    },
    enableScanmode() {
      this.$emit("enableScanmode")
    },
    startCountdown(param, item = {}) {
      if (param) {
        this.timer = setTimeout(() => {
          this.$emit("showQuantityPopup", item.product, item);
        }, this.longPressTimer ? this.longPressTimer : 2000);
      } else {
        clearTimeout(this.timer);
        console.log("Countdown disabled.");
      }
    },
  }
};
</script>

<style scoped></style>