import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import Echo from "laravel-echo";
import {ApiConfigs, authHeader} from "@/_helpers";

import {createI18n} from "vue-i18n";
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

import WebSocketService from './_services/webSocketService';

if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'en')
}
if (localStorage.getItem('user')) {
    if(!localStorage.getItem('user_language')) {
        localStorage.setItem('user_language', 'en')
    }
} else {
    localStorage.removeItem("user_language")
}

const i18n = createI18n({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: !localStorage.getItem('user_language') ? localStorage.getItem('Language') : localStorage.getItem('user_language'),
    fallbackLocale: "en",
    messages: {fr, en, ar},
});

// const themeTemplate = {
//     primaryColor: "#fc8019",
//     confirmationColor: "#09aa29",
//     cart: {
//         selectCartItemColor: "#09aa29"
//     }
// }

// localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))

import Pusher from 'pusher-js';
window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: (process.env.VUE_APP_EDIT_HOST === "true" && localStorage.getItem('host') != null) ? localStorage.getItem('ws') : process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: true,
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    enabledTransports: ["ws", "wss"],
    auth: {
        headers: {...authHeader()},
    },
});



const urlPhoto = process.env.VUE_APP_PHOTO_URL_CHECK;
const longPressTimer = process.env.VUE_APP_LONG_PRESS_TIMER;

WebSocketService.connect();

window.addEventListener('beforeunload', () => {
  WebSocketService.disconnect();
});

const app = createApp(App).use(store).use(router).use(Antd).use(i18n)
app.provide('echo', echo)
app.provide('urlPhoto', urlPhoto)
app.provide('longPressTimer', longPressTimer)
app.mount('#app')
