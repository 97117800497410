<template>
  <a-card
  v-if="loadingCard"
    :loading="loadingCard"
    :class="'bg-[#fff] m-1'">
    <div></div>
    </a-card>
  <div
  v-else
    :loading="loadingCard"
    :class="!loadingCard ? 'bg-[#fff] flex flex-col items-center justify-between rounded-lg overflow-hidden h-full border-4 cursor-pointer transition ease-in-out' : ''">
    <div class="mt-2 flex items-center justify-center">
      <img class="w-20 h-20 object-contain"
        :src="product.thumbnail ? product.thumbnail : product.photo" alt="">
    </div>
    <div class="flex flex-col items-center justify-center mb-3 mx-2 text-sm font['Open_Sans']">
      <div class="h-[34px] flex items-end">
        <span class="leading-4 line-clamp-2 pb-[1px]">{{ product.name }}</span>
      </div>
      <span v-if="!refundMode" class="font-semibold">{{ formatAmount(product.full_price, currencyCode, decimalPrecision) }}</span>
    </div>
  </div>
</template>

<script>
import { currency } from '@/_helpers';

export default {
  name: "LbrxProductItem",
  inject: ['urlPhoto'],
  data() {
    return {
    };
  },
  props: {
    product: {
      required: true,
      value: Object
    },
    loadingCard: {
      required: true,
      value: Boolean
    },
    refundMode: {
      required: false,
      value: Boolean,
      default: false
    },
    currencyCode: {
      required: false,
      value: String
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    }
  },
  computed: {
    getCardStyle() {
      return this.loadingCard ? '24px' : '24px;'
    }
  },
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
      return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
  }
};
</script>

<style>
</style>