import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import pos from '../views/pos/index.vue'
import Home from '../views/home/index.vue'
import Customers from '../views/customers/index.vue'
import AddCustomer from '../views/customers/addCustomer.vue'
import UpdateCustomer from '../views/customers/updateCustomer.vue'
import Login from '../views/auth/login.vue'
import authCode from '../views/auth/authCode.vue'
import Tables from '../views/tables/index.vue'
import Orders from '../views/orders/index.vue'
import Checkout from '../views/checkout/index.vue'
import Cashier from '../views/cashier/index.vue'
import Refund from '../views/refund/index.vue'
import Reports from '../views/reports/index.vue'
import Stock from '../views/stock/index.vue'
// import ClientInterface from '../views/client_interface/index.vue'
// import CheckoutMultiPay from '../components/LbrxMultiPayPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/authentication',
    name: 'Authentication',
    component: authCode,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    component: pos,
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers
      },
      {
        path: '/add-customer',
        name: 'Add Customer',
        component: AddCustomer
      },
      {
        path: '/customer/:id/update',
        name: 'Update Customer',
        component: UpdateCustomer
      },
      {
        path: '/tables',
        name: 'Tables',
        component: Tables
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: '/checkout-single',
        name: 'CheckoutSingle',
        component: Checkout,
        props: { id: 123 } // Static props
      },
      {
        path: '/cashier',
        name: 'Cashier',
        component: Cashier,
      },
      {
        path: '/refund',
        name: 'Refund',
        component: Refund,
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports,
      },
      {
        path: '/stock',
        name: 'Stock',
        component: Stock,
      },
      // {
      //   path: '/checkout-multipay',
      //   name: 'CheckoutMultiPay',
      //   component: CheckoutMultiPay,
      // }
    ]
  },
  // {
  //   path: '/client-interface',
  //   name: 'ClientInterface',
  //   component: ClientInterface
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let posConfig = null;
  const userLoggedIn = localStorage.getItem('user') || null;
  const deviceLoggedIn = localStorage.getItem('device') || null;
  if(deviceLoggedIn) {
    const pos = JSON.parse(deviceLoggedIn);
    posConfig = JSON.parse(pos.config);
  }
  
  if (to.name != "Authentication" && to.name != "ClientInterface" && deviceLoggedIn === null) {
    localStorage.removeItem("user")
    return next('/authentication');
  }
  if (to.meta.authRequired && userLoggedIn === null) {
    return next('/login');
  }

  if (to.path == '/login' && userLoggedIn !== null) {
    return next('/home');
  }

  if (to.name == "Authentication" && deviceLoggedIn !== null) {
    return next('/login');
  }

  if (to.path == "/checkout" && deviceLoggedIn !== null && posConfig) {
    if(posConfig.posConfig.enable_multi_payment == 1) {
      return next('/checkout-multipay');
    } else {
      return next('/checkout-single');

    }
  }

  next();
})

export default router
