<template>
    <div class="bg-[#fff] grid grid-flow-col justify-stretch overflow-hidden">
        <!-- <div v-for="(item, index) in filters" :key="index" @click="selectFilter(item)" class="text-sm py-3 font-semibold hover:text-[#fc8019] cursor-pointer" :class="selectedFilter?.id == item.id ? 'text-[#fc8019] border-b-4 border-primary' : 'border-b-2 border-b-gray-300'">
          <span class="mx-3 text-2xl">{{ item.method?.name }}</span>
        </div> -->
        <div @click="selectFilter(1)" class="text-sm py-3 font-semibold hover:text-primary cursor-pointer" :class="selectedFilter == 1 ? 'text-primary border-b-4 border-primary' : 'border-b-2 border-b-gray-300'">
          <span class="mx-3 text-2xl">{{ paymentMethodDefault[0]?.method?.name }}</span>
        </div>
        <div @click="selectFilter(2)" class="text-sm py-3 font-semibold hover:text-primary cursor-pointer" :class="selectedFilter == 2 ? 'text-primary border-b-4 border-primary' : 'border-b-2 border-b-gray-300'">
          <span class="mx-3 text-2xl">{{ $t('Pos.OtherMethods') }}</span>
        </div>
    </div> 
</template>
    
  <script>
  export default {
    name: "LbrxMenuUnderline",
    components: {
    },
    data() {
      return {
        
      };
    },
    props: {
      filters: {
        required: true,
        value: Array
      },
      selectedFilter: {
        required: false,
        value: Object
      },
      paymentMethodDefault: {
        required: false,
        value: Array
      }
    },
    methods: {
      selectFilter(item) {
        this.$emit("selectFilter", item);
      }
    }
  };
  </script>
    
  <style scoped>
  
  </style>
    