export const currency = {
    formatAmount
};

function formatAmount(amount: string, currencyCode: any = "TND", decimalPrecision: number) {
    if (localStorage.getItem('currencies')) {
        const currencies = JSON.parse(localStorage.getItem('currencies') || "");
        const currency = currencies.find((el: any) => { return el.is_default == 1 });
        if (currency) {
            if (currency.currency.symbol_position == "left") {
                return currencyCode + " " + parseFloat(amount).toFixed(decimalPrecision);
            } else {
                return parseFloat(amount).toFixed(decimalPrecision) + " " + currencyCode;
            }
        } else {
            return parseFloat(amount).toFixed(decimalPrecision) + " " + currencyCode;
        }
    } else {
        return parseFloat(amount).toFixed(decimalPrecision) + " " + currencyCode;
    }
}