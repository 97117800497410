<template>
  <router-view ></router-view>
</template>

<style>
@import './assets/css/main.scss';
@font-face {
    font-family: "Exo 2";
    src: local("Exo 2"),
    url(./assets/fonts/Exo_2/Exo2-VariableFont_wght.ttf) format("truetype");
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
