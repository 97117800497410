<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" :stroke="color" stroke-width="1.5"/>
<path d="M14 10.1278C14 10.542 14.3358 10.8778 14.75 10.8778C15.1642 10.8778 15.5 10.542 15.5 10.1278H14ZM9.75 13.8726C9.75 13.4584 9.41421 13.1226 9 13.1226C8.58579 13.1226 8.25 13.4584 8.25 13.8726H9.75ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7L12.75 7ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM12 11.1062C11.0478 11.1062 10.5431 10.9543 10.2922 10.7864C10.1056 10.6615 10 10.4909 10 10.1278H8.5C8.5 10.8783 8.76936 11.572 9.45777 12.0328C10.0819 12.4507 10.9522 12.6062 12 12.6062V11.1062ZM10 10.1278C10 9.86732 10.1424 9.56819 10.4989 9.30676C10.855 9.04566 11.3833 8.86133 12 8.86133V7.36133C11.0979 7.36133 10.2512 7.6284 9.61196 8.09711C8.97319 8.56549 8.5 9.2746 8.5 10.1278H10ZM12 8.86133C12.6167 8.86133 13.145 9.04566 13.501 9.30676C13.8576 9.56819 14 9.86732 14 10.1278H15.5C15.5 9.27459 15.0268 8.56549 14.388 8.09711C13.7488 7.6284 12.9021 7.36133 12 7.36133V8.86133ZM14.25 13.8726C14.25 14.3084 14.0711 14.5775 13.7369 14.777C13.3572 15.0037 12.7608 15.1391 12 15.1391V16.6391C12.896 16.6391 13.7997 16.4865 14.5057 16.065C15.2573 15.6163 15.75 14.8772 15.75 13.8726H14.25ZM12 15.1391C11.3002 15.1391 10.7004 14.9471 10.2971 14.676C9.88907 14.4018 9.75 14.1036 9.75 13.8726H8.25C8.25 14.7554 8.78251 15.4654 9.46029 15.921C10.1428 16.3797 11.0429 16.6391 12 16.6391V15.1391ZM12 12.6062C12.9582 12.6062 13.5279 12.7501 13.8421 12.9537C14.088 13.1131 14.25 13.354 14.25 13.8726H15.75C15.75 12.951 15.412 12.1837 14.6579 11.6949C13.9721 11.2504 13.0418 11.1062 12 11.1062V12.6062ZM12.75 8.11133L12.75 7L11.25 7L11.25 8.11133L12.75 8.11133ZM11.25 15.8891V17H12.75V15.8891H11.25Z" :stroke="color"/>
</svg>
</template>

<script>

export default {
  name: 'iconCash',
  props: {
    size: Number,
    color: String
  }
}
</script>