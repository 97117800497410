<template>
    <div class="mx-2">
        <input @keyup.enter="confirmCoupon" :value="textInput" type="text"
          class="w-full text-3xl text-black rounded-sm border-2 border-gray-300 px-2 font-medium">
    </div>
    <div  v-if="letters.length"
        :style="{ 'width': selectedLanguage === 'ar' ? '895px' : '', 'direction': selectedLanguage === 'ar' ? 'rtl' : '' }"
        class="keyboard-container flex flex-wrap justify-center">
        <div v-for="(letter, index) in numbers" :key="index" @click="clickLetter(letter)"
            :class="{ 'clicked': letter.clicked }" class="single-digit">
            <div class="number">
                {{ letter.letter }}
            </div>
        </div>
        <div v-for="(letter, index) in letters" :key="index" @click="clickLetter(letter)"
            :class="{ 'clicked': letter.clicked }" class="single-letter">
            <div class="letter">
                {{ letter.letter }}
            </div>
        </div>
        <div @click="addSpace()" :style="{ 'width': selectedLanguage !== 'ar' ? '213px' : '140px' }"
            :class="{ 'clicked': spaceClicked }" class="single-letter space-button col-9">
            <div :style="{ 'transform': selectedLanguage === 'ar' ? 'scaleX(-1)' : '', 'margin-left': selectedLanguage !== 'ar' ? '-4px' : '', 'margin-right': selectedLanguage === 'ar' ? '-4px' : '' }"
                style="padding-top: 8px;direction: ltr">
            </div>
        </div>
        <div @click="removeLetter()" :class="{ 'clicked': removeClicked }" class="single-letter delete-button col-3">
            <div :style="{ 'transform': selectedLanguage === 'ar' ? 'scaleX(-1)' : '', 'margin-left': selectedLanguage !== 'ar' ? '-4px' : '', 'margin-right': selectedLanguage === 'ar' ? '-4px' : '' }"
                style="padding-top: 8px;padding-left: 12px;direction: ltr">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path
                        d="M43 48H5C2.242 48 0 45.756 0 43V5C0 2.244 2.242 0 5 0H43C45.758 0 48 2.244 48 5V43C48 45.756 45.758 48 43 48ZM5 2C3.346 2 2 3.346 2 5V43C2 44.654 3.346 46 5 46H43C44.654 46 46 44.654 46 43V5C46 3.346 44.654 2 43 2H5Z"
                        :fill="primaryColor" />
                    <path
                        d="M37 36H19C18.718 36 18.45 35.88 18.26 35.672L8.25999 24.672C7.91399 24.29 7.91399 23.708 8.25999 23.326L18.26 12.326C18.45 12.12 18.718 12 19 12H37C38.654 12 40 13.346 40 15V33C40 34.654 38.654 36 37 36ZM19.442 34H37C37.55 34 38 33.552 38 33V15C38 14.448 37.55 14 37 14H19.442L10.352 24L19.442 34Z"
                        :fill="primaryColor" />
                    <path
                        d="M33 29C32.744 29 32.488 28.902 32.292 28.708L24.292 20.708C23.902 20.318 23.902 19.684 24.292 19.294C24.682 18.904 25.316 18.904 25.706 19.294L33.706 27.294C34.096 27.684 34.096 28.318 33.706 28.708C33.512 28.902 33.256 29 33 29Z"
                        :fill="primaryColor" />
                    <path
                        d="M25 29C24.744 29 24.488 28.902 24.292 28.708C23.902 28.318 23.902 27.684 24.292 27.294L32.292 19.294C32.682 18.904 33.316 18.904 33.706 19.294C34.096 19.684 34.096 20.318 33.706 20.708L25.706 28.708C25.512 28.902 25.256 29 25 29Z"
                        :fill="primaryColor" />
                </svg>
            </div>
        </div>
        <div @click="closePopup()" :style="{ 'width': selectedLanguage !== 'ar' ? '213px' : '140px' }"
            :class="{ 'clicked': spaceClicked }" class="single-letter space-button col-9 flex justify-center items-center">
            <div :style="{ 'transform': selectedLanguage === 'ar' ? 'scaleX(-1)' : '', 'margin-left': selectedLanguage !== 'ar' ? '-4px' : '', 'margin-right': selectedLanguage === 'ar' ? '-4px' : '' }"
                style="direction: ltr">
                <div class="letter">{{ $t('Pos.Enter') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { locale } from "moment";

export default {
    name: 'LbrxKeyboard',
    props: {
        selectedLanguage: { required: true, type: String },
        textInput: {
            required: false,
            type: String
        }
    },
    watch: {
        selectedLanguage(newValue) {
            if (newValue === 'ar') {
                this.letters = this.arabicLetters;
            } else if (newValue === 'en') {
                this.letters = this.englishLetters;
            } else {
                this.letters = this.frenchLetters;
            }
        }
    },
    emits: ['addLetter', 'removeLetter'],
    data() {
        return {
            removeClicked: false,
            spaceClicked: false,
            primaryColor: "#f1f1f1",
            primaryColor2: localStorage.getItem('themeTemplate') ? JSON.parse(localStorage.getItem('themeTemplate')).primaryColor : "#202020",
            secondaryColor: "#fff",
            textColor: "#000",
            clickColor: "#fff",
            englishLetters: [
                { letter: 'Q', clicked: false },
                { letter: 'W', clicked: false },
                { letter: 'E', clicked: false },
                { letter: 'R', clicked: false },
                { letter: 'T', clicked: false },
                { letter: 'Y', clicked: false },
                { letter: 'U', clicked: false },
                { letter: 'I', clicked: false },
                { letter: 'O', clicked: false },
                { letter: 'P', clicked: false },
                { letter: 'A', clicked: false },
                { letter: 'S', clicked: false },
                { letter: 'D', clicked: false },
                { letter: 'F', clicked: false },
                { letter: 'G', clicked: false },
                { letter: 'H', clicked: false },
                { letter: 'J', clicked: false },
                { letter: 'K', clicked: false },
                { letter: 'L', clicked: false },
                { letter: 'Z', clicked: false },
                { letter: 'X', clicked: false },
                { letter: 'C', clicked: false },
                { letter: 'V', clicked: false },
                { letter: 'B', clicked: false },
                { letter: 'N', clicked: false },
                { letter: 'M', clicked: false }
            ],
            numbers: [
            { letter: '1', clicked: false },
                { letter: '2', clicked: false },
                { letter: '3', clicked: false },
                { letter: '4', clicked: false },
                { letter: '5', clicked: false },
                { letter: '6', clicked: false },
                { letter: '7', clicked: false },
                { letter: '8', clicked: false },
                { letter: '9', clicked: false },
                { letter: '0', clicked: false },
            ],
            frenchLetters: [
                { letter: 'A', clicked: false },
                { letter: 'Z', clicked: false },
                { letter: 'E', clicked: false },
                { letter: 'R', clicked: false },
                { letter: 'T', clicked: false },
                { letter: 'Y', clicked: false },
                { letter: 'U', clicked: false },
                { letter: 'I', clicked: false },
                { letter: 'O', clicked: false },
                { letter: 'P', clicked: false },
                { letter: 'Q', clicked: false },
                { letter: 'S', clicked: false },
                { letter: 'D', clicked: false },
                { letter: 'F', clicked: false },
                { letter: 'G', clicked: false },
                { letter: 'H', clicked: false },
                { letter: 'J', clicked: false },
                { letter: 'K', clicked: false },
                { letter: 'L', clicked: false },
                { letter: 'M', clicked: false },
                { letter: 'W', clicked: false },
                { letter: 'X', clicked: false },
                { letter: 'C', clicked: false },
                { letter: 'V', clicked: false },
                { letter: 'B', clicked: false },
                { letter: 'N', clicked: false }
            ],
            arabicLetters: [
                { letter: 'د', clicked: false },
                { letter: 'ج', clicked: false },
                { letter: 'ح', clicked: false },
                { letter: 'خ', clicked: false },
                { letter: 'ه', clicked: false },
                { letter: 'ع', clicked: false },
                { letter: 'غ', clicked: false },
                { letter: 'ف', clicked: false },
                { letter: 'ق', clicked: false },
                { letter: 'ث', clicked: false },
                { letter: 'ص', clicked: false },
                { letter: 'ض', clicked: false },
                { letter: 'ذ', clicked: false },
                { letter: 'ط', clicked: false },
                { letter: 'ك', clicked: false },
                { letter: 'م', clicked: false },
                { letter: 'ن', clicked: false },
                { letter: 'ت', clicked: false },
                { letter: 'ا', clicked: false },
                { letter: 'ل', clicked: false },
                { letter: 'ب', clicked: false },
                { letter: 'ي', clicked: false },
                { letter: 'س', clicked: false },
                { letter: 'ش', clicked: false },
                { letter: 'ظ', clicked: false },
                { letter: 'ز', clicked: false },
                { letter: 'و', clicked: false },
                { letter: 'ة', clicked: false },
                { letter: 'ى', clicked: false },
                { letter: 'ر', clicked: false },
                { letter: 'ؤ', clicked: false },
                { letter: 'ء', clicked: false },
                { letter: 'ئ', clicked: false }
            ],
            letters: [],
        }
    },
    created() {
        if (this.selectedLanguage === 'ar') {
            this.letters = this.arabicLetters;
        } else if (this.selectedLanguage === 'en') {
            this.letters = this.englishLetters;
        } else {
            this.letters = this.frenchLetters;
        }
    },
    methods: {
        addSpace() {
            this.spaceClicked = true;
            this.$emit("addLetter", ' ');
            setTimeout(() => {
                this.spaceClicked = false;
            }, 100);
        },
        removeLetter() {
            this.removeClicked = true;
            this.$emit("removeLetter");
            setTimeout(() => {
                this.removeClicked = false;
            }, 100);
        },
        clickLetter(letter) {
            letter.clicked = true;
            this.$emit("addLetter", letter.letter);
            setTimeout(() => {
                letter.clicked = false;
            }, 100);
        },
        closePopup() {
            this.$emit("closePopup");
        }
    }
}
</script>

<style scoped>
.keyboard-container {
    width: 750px;
    overflow: hidden !important;
    background: v-bind(secondaryColor);
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
}

.single-letter {
    height: 64px;
    width: 64px;
    margin: 5px;
    background: v-bind(primaryColor);
    cursor: pointer;
}

.single-digit {
    height: 64px;
    width: 64px;
    margin: 5px;
    background: v-bind(primaryColor2);
    cursor: pointer;
}

.clicked {
    background: v-bind(clickColor) !important;
}

.delete-button {
    background: v-bind(primaryColor2);
}

.letter {
    margin: auto;
    color: v-bind(textColor);
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 60px;
}
.number {
    margin: auto;
    color: white;
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 60px;
}
</style>