<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
<path d="M17.2624 10.2458L19.0165 8.49167C19.4244 8.08385 19.6283 7.87994 19.7507 7.66781C20.0831 7.09216 20.0831 6.38293 19.7507 5.80727C19.6283 5.59515 19.4244 5.39123 19.0165 4.98341C18.6087 4.57559 18.4048 4.37168 18.1927 4.2492C17.617 3.91685 16.9078 3.91685 16.3321 4.2492C16.12 4.37168 15.9161 4.57559 15.5083 4.98341L13.7541 6.73754C12.9272 7.56445 12.5138 7.9779 12 7.9779C11.4862 7.9779 11.0728 7.56445 10.2459 6.73754L8.49174 4.98341C8.08391 4.57559 7.88 4.37168 7.66787 4.2492C7.09222 3.91685 6.38299 3.91685 5.80734 4.2492C5.59521 4.37168 5.3913 4.57559 4.98347 4.98341C4.57565 5.39123 4.37174 5.59515 4.24927 5.80727C3.91691 6.38293 3.91691 7.09216 4.24926 7.66781C4.37174 7.87994 4.57565 8.08385 4.98347 8.49168L6.7376 10.2458C7.56451 11.0727 7.97796 11.4862 7.97796 11.9999C7.97796 12.5137 7.56451 12.9272 6.7376 13.7541L4.98347 15.5082C4.57565 15.916 4.37174 16.1199 4.24926 16.3321C3.91691 16.9077 3.91691 17.617 4.24926 18.1926C4.37174 18.4047 4.57565 18.6086 4.98347 19.0165C5.3913 19.4243 5.59521 19.6282 5.80733 19.7507C6.38299 20.083 7.09222 20.083 7.66787 19.7507C7.88 19.6282 8.08391 19.4243 8.49174 19.0165L10.2459 17.2623C11.0728 16.4354 11.4862 16.022 12 16.022C12.5138 16.022 12.9272 16.4354 13.7541 17.2623L15.5083 19.0165C15.9161 19.4243 16.12 19.6282 16.3321 19.7507C16.9078 20.083 17.617 20.083 18.1927 19.7507C18.4048 19.6282 18.6087 19.4243 19.0165 19.0165C19.4244 18.6086 19.6283 18.4047 19.7507 18.1926C20.0831 17.617 20.0831 16.9077 19.7507 16.3321C19.6283 16.1199 19.4244 15.916 19.0165 15.5082L17.2624 13.7541C16.4355 12.9272 16.022 12.5137 16.022 11.9999C16.022 11.4862 16.4355 11.0727 17.2624 10.2458Z" stroke="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



</template>

<script>

export default {
  name: 'iconCloseFill',
  props: {
    size: Number,
    color: String
  }
}
</script>