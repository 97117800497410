<template>
    <div class="flex flex-col overflow-y-auto flex-grow px-4" :class="isPortrait ? 'h-[40vh] mb-4' : 'w-[58%]'">
        <LbrxLoaderBlur v-if="showLoader" />
        <LbrxFilterBar :filters="filters" :selectedFilter="selectedFilter" @selectFilter="selectFilter"
            :margin="'16px 0 0 0'" />
        <LbrxFilterBar v-if="selectedFilter.id == 1" :filters="filtersData" :selectedFilter="selectedFilterData"
            @selectFilter="selectFilterData" :fitWidth="true" :margin="'8px 0 8px auto'" :title="$t('Pos.FilterBy')" />
        <div v-if="selectedFilter.type == 'offline'" class="flex mb-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ $t('Pos.OfflineOrders') }}
            </span>
            <button v-if="!showAddCustomer" @click="showAddCustomer = true"
                class="text-white font-semibold bg-primary py-2 px-3 flex items-center gap-3 border-none rounded-md transition ease-in-out delay-150 hover:-translate-y-1 duration-300">
                <iconRefresh :size="20" :color="'#fff'" />
                {{ $t("Pos.SyncAllOrders") }}
            </button>
        </div>
        <div class="h-auto mb-3">
            <LbrxListOrders v-if="selectedFilter.id == 1" :sales="sales" @selectSale="selectSale"
                @searchSales="searchSales" :totalItems="totalItems" :perPage="perPage"
                @handlePageChange="handlePageChange" :posConfig="posConfig" :currencyCode="currencyCode"
                :decimalPrecision="posConfig.posConfig.decimalPrecision" :currencyDirection="currencyDirection" />
            <LbrxListCarts v-if="selectedFilter.id == 2" :carts="carts" @selectCart="selectCart"
                :totalItems="totalItems" :perPage="perPage" @handlePageChange="handlePageChangeCarts"
                :decimalPrecision="posConfig.posConfig.decimalPrecision" :currencyCode="currencyCode"
                :currencyDirection="currencyDirection" />
        </div>
    </div>
    <div :class="isPortrait ? '' : 'w-[32%]'">
        <LbrxOrdersSideBar v-if="selectedFilter.id == 1 && !isPortrait" :selectedSale="selectedSale" :selectedFilter="selectedFilter"
            @confirmOrder="confirmOrder" @deleteOrder="openDeleteModal" :loadingPrinting="loadingPrinting"
            :currencyCode="currencyCode" :currencyDirection="currencyDirection"
            :decimalPrecision="posConfig.posConfig.decimalPrecision" />
        <LbrxOrdersSideBarPortrait v-else-if="selectedFilter.id == 1 && isPortrait" :selectedSale="selectedSale" :selectedFilter="selectedFilter"
            @confirmOrder="confirmOrder" @deleteOrder="openDeleteModal" :loadingPrinting="loadingPrinting"
            :currencyCode="currencyCode" :currencyDirection="currencyDirection"
            :decimalPrecision="posConfig.posConfig.decimalPrecision" :isPortrait="isPortrait" />
        <LbrxCartSideBar v-if="selectedFilter.id == 2 && !isPortrait" :selectedCart="selectedCart" :selectedFilter="selectedFilter"
            @confirmCart="confirmCart" :loading="loading" :currencyCode="currencyCode"
            :currencyDirection="currencyDirection" />
        <LbrxCartSideBarPortrait v-else-if="selectedFilter.id == 2 && isPortrait" :selectedCart="selectedCart" :selectedFilter="selectedFilter"
            @confirmCart="confirmCart" :loading="loading" :currencyCode="currencyCode"
            :currencyDirection="currencyDirection" :isPortrait="isPortrait" />
    </div>
    <LbrxPincodePopup :active="modalCode.active" @closePopup="closePopup" @confirmCode="confirmCode"
        :title="$t('Pos.CancelOrder')" />
    <LbrxInfoPopup :active="modalInfo.active" @closePopup="closePopup" :type="'message'"
        :message="$t('Pos.NoSaleSelected')" />
</template>

<script>
import LbrxOrdersSideBar from "@/components/LbrxOrdersSideBar.vue"
import LbrxCartSideBar from "@/components/LbrxCartSideBar.vue"
import LbrxListOrders from "@/components/LbrxListOrders.vue"
import LbrxListCarts from "@/components/LbrxListCarts.vue"
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import iconRefresh from "@/components/icons/iconRefresh.vue"
import { saleService, cartService, authService } from "@/_services"
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import LbrxPincodePopup from "@/components/LbrxPincodePopup.vue"
import LbrxInfoPopup from "@/components/LbrxInfoPopup.vue"
import router from "@/router";
import { notification } from 'ant-design-vue';
import webSocketService from "@/_services/webSocketService"
import LbrxOrdersSideBarPortrait from "@/components/LbrxOrdersSideBarPortrait.vue"
import LbrxCartSideBarPortrait from "@/components/LbrxCartSideBarPortrait.vue"

export default {
    name: "orderIndex",
    components: {
        LbrxOrdersSideBar,
        LbrxListOrders,
        iconRefresh,
        LbrxFilterBar,
        LbrxLoaderBlur,
        LbrxListCarts,
        LbrxCartSideBar,
        LbrxPincodePopup,
        LbrxInfoPopup,
        LbrxOrdersSideBarPortrait,
        LbrxCartSideBarPortrait
    },
    data() {
        return {
            modal: {
                active: false
            },
            modalCode: {
                active: false
            },
            modalInfo: {
                active: false
            },
            sales: [],
            carts: [],
            selectedFilter: {},
            selectedFilterData: {},
            selectedParam: "",
            selectedSale: {},
            selectedCart: {},
            currentPage: 1,
            totalItems: 1,
            perPage: 20,
            filters: [{ name: this.$t('Pos.OrderHistory'), id: 1 }, { name: this.$t('Pos.OrderOnhold'), id: 2 }, { name: this.$t('Pos.OrderOffline'), id: 3 }],
            filtersData: [{ name: this.$t('Pos.Cashier'), id: 1 }, { name: this.$t('Pos.User'), id: 2 }, { name: this.$t('Pos.All'), id: 3 }],
            pos: {},
            posConfig: {},
            socket: null,
            loadingPrinting: false,
            loading: false,
            showLoader: false,
            currency: {},
            currencyCode: "",
            currencyDirection: "right",
            isPortrait: window.matchMedia("(orientation: portrait)").matches
        };
    },
    created() {
        this.getSalesByCategory(this.selectedParam, this.currentPage, this.perPage);
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
                this.currencyDirection = this.currency.currency.symbol_position;
            }
        }
    },
    mounted() {
        this.selectedFilter = this.filters[0];
        this.selectedFilterData = this.filtersData[2];
        this.socket = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        decimalPrecision() {
            return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
        }
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        closePopup() {
            this.modalCode.active = false;
            this.modalInfo.active = false;
        },
        confirmCode(code) {
            this.deleteOrder(code);
        },
        selectSale(item) {
            this.selectedSale = item;
        },
        findCartIndex(product) {
            return this.cart.findIndex((p) => p.productId === product.id);
        },
        findCartItem(product) {
            return this.cart.findIndex((p) => p.productId === product.productId);
        },
        selectFilter(item) {
            this.selectedFilter = item;
            if (item.id == 1) {
                this.getSalesByCategory(this.selectedParam, this.currentPage, this.perPage);
            }
            else if (item.id == 2) {
                this.getCarts(this.currentPage, this.perPage);
            }
        },
        selectFilterData(item) {
            this.selectedFilterData = item;
            if (item.id == 1) {
                this.selectedParam = "&device_id=" + this.pos.id;
                this.getSalesByCategory(this.selectedParam, this.currentPage, this.perPage)
            } else if (item.id == 2) {
                this.selectedParam = "&user_id=" + JSON.parse(localStorage.getItem("user")).user.id;
                this.getSalesByCategory(this.selectedParam, this.currentPage, this.perPage)
            } else {
                this.selectedParam = "";
                this.getSalesByCategory("", this.currentPage, this.perPage)
            }
        },
        confirmOrder() {
            if (this.selectedSale.id)
                this.printTicket();
        },
        getSalesByCategory(param, currentPage, perPage) {
            this.showLoader = true;
            saleService.getByCategory(param, currentPage, perPage).then((res) => {
                this.sales = res.data;
                this.totalItems = res.meta.total;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        openDeleteModal() {
            if (this.selectedSale.id) {
                this.modalCode.active = true;
            } else {
                this.modalInfo.active = true;
            }
        },
        // getSales(currentPage, perPage) {
        //     this.showLoader = true;
        //     saleService.getAll(currentPage, perPage).then((res) => {
        //         this.sales = res.data;
        //         this.totalItems = res.meta.total;
        //     }).catch((error) => {
        //         console.log("error api : get products", error);
        //     }).finally(() => {
        //         this.showLoader = false;
        //     });
        // },
        getCarts(currentPage, perPage) {
            this.showLoader = true;
            cartService.getAll(currentPage, perPage).then((res) => {
                this.carts = res.data;
                this.totalItems = res.meta.total;
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        searchSales(filter) {
            if (filter.length == 0) {
                this.getSalesByCategory(this.selectedParam, 1, 10);
            } else {
                saleService.filter(filter).then((res) => {
                    this.sales = res.data;
                }).catch((error) => {
                    console.log("error api : ", error);
                });
            }
        },
        handlePageChange(currentPage) {
            this.getSalesByCategory(this.selectedParam, currentPage, this.perPage);
        },
        handlePageChangeCarts(currentPage) {
            this.getCarts(currentPage, this.perPage);
        },
        selectCart(item) {
            this.showLoader = true;
            cartService.show(item.id).then((res) => {
                this.selectedCart = res.data;
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        confirmCart() {
            this.loading = true;
            localStorage.setItem("cart", JSON.stringify(this.selectedCart))
            if (this.selectedCart.customer) {
                localStorage.setItem("customer", JSON.stringify(this.selectedCart.customer))
            }
            setTimeout(() => {
                this.loading = false;
                router.push("/home");
            }, 1000);
        },
        deleteOrder(code) {
            this.showLoader = true;
            saleService.delete(this.selectedSale.id, "?pin=" + code).then((res) => {
                this.selectedSale = {};
                this.getSalesByCategory(this.selectedParam, this.currentPage, this.perPage)
                this.closePopup();
                this.showNotification(this.$t('Pos.DeleteOrderSuccess'));
            }).catch((error) => {
                console.log("error api :", error);
                if (error == 400)
                    this.showErrorNotification(this.$t('Pos.ErrorLoginPin'));
                else if (error == 403)
                    this.showErrorNotification(this.$t('Pos.NoPrivilege'));
                else
                    this.showErrorNotification(this.$t('Pos.ErrorTry'));
            }).finally(() => {
                this.showLoader = false;
                this.pinCode = "";
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        connectSocket() {
            //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
            const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

            this.socket = new WebSocket("ws://localhost:8765");
            // this.ticketPrinted = true;

            this.socket.onopen = (event) => {
                this.socket.send(token); // send the token as soon as the connection is established
            };

            this.socket.onmessage = (event) => {
                if (event.data == "Authentication successful") {
                    // this.sendMessage();
                }
            };

            this.socket.onerror = (error) => {
                console.error(`WebSocket Error: ${error}`);
            };

            this.socket.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event}`);
            };
        },
        sendMessage() {
            if (this.socket.readyState === WebSocket.OPEN) {
                let saleObject = this.selectedSale;
                saleObject.table = {};
                if (saleObject.payments.length != 0) {
                    saleObject.payments[0].amount_given = Math.ceil((parseFloat(saleObject?.payments[0]?.change_due) +
                        parseFloat(saleObject?.payments[0]?.amount_paid))).toFixed(this.decimalPrecision);
                }
                const message = {
                    action: "print_history",
                    data: saleObject
                };
                this.socket.send(JSON.stringify(message));
                // this.ticketPrinted = true;
            } else {
                console.error("Socket is not open. Can't send message");
            }
        },
        printTicket() {
            this.loadingPrinting = true;
            this.sendMessage();
            setTimeout(() => {
                this.loadingPrinting = false;
            }, 2000);
        },
    }
};
</script>

<style scoped></style>